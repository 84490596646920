import UserAuthForm from "./form/UserAuthForm";
import { LoginOptionItem, LoginOptions } from "./form/LoginOptions";
import LoginFacebook from "./facebook/LoginFacebook";
import LoginGoogle from "./google/LoginGoogle";
import { PasswordRegister } from "./password/PasswordRegister";

export default function Register(props) {
  return (
    <UserAuthForm {...props} title="register">
      <LoginGoogle />
      <LoginFacebook />
      <PasswordRegister {...props} />

      <LoginOptions>
        <LoginOptionItem name="have_account" path="/login" />
      </LoginOptions>
    </UserAuthForm>
  );
}
