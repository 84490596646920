import {
	Avatar, Card,
	CardActionArea,
	CardContent,
	CircularProgress,
	Container, 
	CssBaseline, 
	Icon, 
	IconButton, 
	Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LanguageIcon from '@mui/icons-material/Language';
import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../App';
import Show from '../common/Show';

function Copyright() {
	return (
		<Typography variant="body2" align="center">
			{'Copyright © '}
			<Link to="/" className="secondary">
				E-Bar
			</Link>
			{' '}
			{new Date().getFullYear()}
			.
		</Typography>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		// background: `url('${process.env.PUBLIC_URL}/images/background.webp')`,
		// backgroundRepeat: 'round',
		// backgroundSize: 'cover',
		position: 'relative',
		width: '100%',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 'none',
	},
	paper: {
		width: '100%',
		maxWidth: 420,
		paddingTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: 'transparent', // theme.palette.secondary.main,
		width: 80,
		height: 80,
		borderRadius: 'unset',
		marginBottom: theme.spacing(2),
	},
	avatarSmall: {
		margin: theme.spacing(1),
		backgroundColor: 'transparent', // theme.palette.secondary.main,
		width: 56,
		height: 56,
		borderRadius: 'unset',
		marginBottom: theme.spacing(1),
	},
	'@keyframes slideUp': {
		'0%': {
			opacity: 0,
			transform: 'translateY(100%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(0%)',
		},
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		animation: '$slideUp 0.3s',
		animationFillMode: 'both'
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
		'& span': {
			justifyContent: 'flex-start',
		},
	},
	hidden: {
		display: 'none',
	},
	error: {
		color: 'red',
	},
	copyright: {
		position: 'fixed',
		bottom: 0,
		margin: '0 auto',
		fontVariant: 'all-small-caps',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	langDiv: {
		display: 'flex',
		width: '100%',
		whiteSpace: 'nowrap',
	},
	hr: {
		'&::before': {
			background: '#333',
			// content: 'url(/favicon-16x16.png)',
			padding: '0 4px',
			position: 'relative',
			top: -13,
		},
		border: 'none',
		borderTop: '3px double #e3e3e3',
		color: '#e3e3e3',
		overflow: 'visible',
		textAlign: 'center',
		height: 5,
		width: '100%',
		borderRadius: '100%',
	},
	cardRoot: {
		width: '100%',
		position: 'relative',
		display: 'inline-flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 25,
	},
	action: {
		zIndex: 1,
		display: 'inline-flex',
		textAlign: 'left',
		justifyContent: 'flex-start',
		textTransform: 'uppercase',
	},
	disableAction: {
		cursor: 'default',
	},
	media: {
		width: 'auto',
		margin: theme.spacing(1),
		padding: 0,
	},
	delete: {
	},
	forward: {
	},

}));

const languages = {
	en: 'English',
	mk: 'Македонски',
};

export default function Language(props) {
	const classes = useStyles();

	const user = useContext(UserContext);

	const history = useHistory();

	const onSetLanguage = (e, lang) => {
		const backUrl = document.location.hash.replace('#', '');
		e.stopPropagation();
		props.setLanguage(lang);
		if (backUrl.length > 0) {
			history.push(backUrl);
		} else {
			history.push('/');
		}
		// console.log(backUrl);
		// console.log(location);
	};

	return (
		<Container component="main" className={classes.root}>
			<CssBaseline />
			<div className={classes.paper}>
				<Show if={props.scannedRestaurant}>
					<Avatar className={classes.avatar}>
						<img src={props.scannedRestaurant?.imageLogo} alt={props.scannedRestaurant?.name} width="80px" height="80px" />
					</Avatar>
					<Typography variant="h6">{props.scannedRestaurant?.name}</Typography>
					<br />
				</Show>
				<Show if={!props.scannedRestaurant}>
					<Avatar className={classes.avatar}>
						<img src={`${process.env.PUBLIC_URL}/android-icon-72x72.png`} alt={user.translate('e_bar')} width="72px" height="72px" />
					</Avatar>
				</Show>
				<div className={classes.langDiv}>
					<Typography variant="overline">
						{user.translate('select_language')}
					</Typography>
				</div>

				<hr className={classes.hr} />
				{
					props.isLoading
						? <CircularProgress />
						: <div className={classes.form}>{
							props.languages.map((lang, index) => (
								<Card key={lang} className={classes.cardRoot} style={{ animationDelay: `${(index + 1) * 50}ms` }}>
									<CardActionArea className={classes.action} onClick={(e) => onSetLanguage(e, lang)}>
										<Icon className={classes.media}>
											<LanguageIcon />
										</Icon>
										<CardContent>
											<Typography variant="body2" component="h2">
												{languages[lang.toLowerCase()]}
											</Typography>
										</CardContent>
									</CardActionArea>
									<IconButton color="primary" className={classes.forward} onClick={(e) => onSetLanguage(e, lang)}>
										<ArrowForwardIosIcon />
									</IconButton>
								</Card>
							))
						}
						</div>
				}
				<div className={classes.copyright}>
					<Show if={props.scannedRestaurant}>
						<Avatar className={classes.avatarSmall}>
							<img src={`${process.env.PUBLIC_URL}/android-icon-72x72.png`} alt={user.translate('e_bar')} width="54px" height="54px" />
						</Avatar>
					</Show>
					<Copyright />
				</div>
			</div>
		</Container>
	);
}
