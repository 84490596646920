import { Box, CircularProgress } from "@mui/material";

export default function LoadingBox({
  children,
  loading,
  bottomGuther,
  sx,
  noFade,
}) {
  return (
    <Box sx={{ pb: bottomGuther ?? 0, ...sx, position: "relative", flex: 1 }}>
      {children}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            mb: bottomGuther ?? 0,
            bgcolor: noFade ? undefined : "#00000088",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 1,
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
}
