import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../App";
import { PlaceContext } from "../home/Restaurant";
import ProductDialog from "./ProductDialog";
import { useOrdersQuery } from "./gql/useOrdersQuery";

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    // backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    "& .Mui-disabled  .gridItemImage": {
      opacity: 0.5,
    },
  },
  pageTitle: {
    marginBottom: 0,
  },
  dateCreated: {
    width: "100%",
    textAlign: "right",
    marginBottom: theme.spacing(1),
  },
  dateCreatedOrders: {
    width: "100%",
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  orderItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: 600,
    minWidth: "calc(100vw - 30px)",
    minHeight: 120,
    background: "#ffffff22",
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative",
    "@media screen and (min-width: 400px)": {
      minWidth: 400,
    },
  },
  orderItemOrders: {
    minHeight: 60,
    "@media screen and (max-width: 400px)": {
      minHeight: "unset",
    },
  },
  orderItemLabel: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
  },
  orderItemLabelOrders: {
    height: 60,
    padding: `0 ${theme.spacing(1)} 0px 2px`,
    alignItems: "baseline",
    justifyContent: "space-between",
    position: "relative",
    "@media screen and (max-width: 400px)": {
      height: "100%",
    },
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
  },
  titleDivOrders: {
    width: "30%",
    "@media screen and (max-width: 400px)": {
      width: "25%",
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    marginBottom: theme.spacing(1),
  },
  titleOrders: {
    textDecoration: "underline",
    "@media screen and (max-width: 400px)": {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingLeft: 30,
      // textDecorationColor: theme.palette.secondary.main
    },
    "@media screen and (max-width: 340px)": {
      fontSize: 12,
    },
  },
  titleOrdersCanceled: {
    textDecoration: "line-through",
    "@media screen and (max-width: 400px)": {
      textDecoration: "line-through",
      // textDecorationColor: theme.palette.secondary.main
    },
  },
  info: {
    position: "absolute",
    bottom: 0,
    right: 10,
    padding: 5,
  },
  infoOrders: {
    position: "absolute",
    left: -40,
    padding: 5,
    "@media screen and (max-width: 400px)": {
      left: -5,
    },
  },
  canceled: {
    position: "absolute",
    bottom: 0,
    right: -16,
    padding: 5,
    "@media screen and (max-width: 400px)": {
      right: 5,
      bottom: "unset",
    },
  },
  options: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    textOverflow: "ellipsis",
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    maxWidth: 250,
    minWidth: 50,
  },
  infoDiv: {
    minWidth: 120,
    display: "inline-block",
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  infoDivOrders: {
    minWidth: 220,
    display: "flex",
    position: "relative",
    marginRight: theme.spacing(2),
    "@media screen and (max-width: 400px)": {
      marginRight: 0,
      position: "unset",
    },
    "@media screen and (max-width: 340px)": {
      fontSize: 11,
      minWidth: 200,
    },
  },
  hr: {
    border: "none",
    borderTop: "3px double #e3e3e3",
    color: "#e3e3e3",
    overflow: "visible",
    textAlign: "center",
    height: 5,
    width: "95%",
    marginTop: theme.spacing(1),
    borderRadius: "100%",
  },
  vlr: {
    border: "none",
    border: "1px solid",
    // boxShadow: "2px 3px 0px",
    color: theme.palette.text.secondary,
    overflow: "visible",
    textAlign: "center",
    width: 0,
    height: "95%",
    margin: `0 5px`,
    borderRadius: "100%",
  },
  status: {
    width: "100%",
    textAlign: "left",
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  statusOrders: {
    color: theme.palette.secondary.main,
  },
  total: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(1),
    textAlign: "right",
    width: "100%",
  },
}));

// React Function Component
export default function Orders(props) {
  const classes = useStyles();
  const { findMenuItem } = props;

  const { placeId } = useParams();

  const place = useContext(PlaceContext);
  const user = useContext(UserContext);

  const finished = props.type === "orders";

  const [selectedProduct, setSelectedProduct] = useState(false);

  let total = 0;

  const { loading, data: orderResult } = useOrdersQuery(placeId, finished);
  if (place.place == null) {
    return <div></div>;
  }

  const receipts = orderResult?.userMenu_orders?.receipts;

  const questionerReceipt = receipts?.find(
    (receipt) => receipt.questioner != null
  );
  const availableQuestioner = questionerReceipt?.questioner;

  const onOpenProduct = (orderItem) => {
    let product = findMenuItem(orderItem.menuItemId);
    if (product) setSelectedProduct(product);
  };
  const onProductClose = () => {
    setSelectedProduct(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h3 className={classes.pageTitle}>{place.place.name}</h3>
        <p>
          {finished
            ? user.translate("all_orders")
            : user.translate("active_orders")}
        </p>
        {!receipts && (
          <p>
            {user.translate("no_orders_made")}. {user.translate("please_goto")}{" "}
            <a href={`/place/${placeId}/menu`}>{user.translate("menu")}</a>.
          </p>
        )}
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          {receipts?.map((receipt) => {
            let status = "status_" + receipt.status;
            // let table = receipt.table;

            if (!finished) {
              //active orders
              return (
                <>
                  <div className={classes.dateCreated}>
                    {user.translate("createda")}:{" "}
                    {user.formatDate(receipt.created)}
                  </div>

                  {receipt.orders &&
                    receipt.orders.map((orderItem) => {
                      let price = Math.round(orderItem.price);
                      total += price * orderItem.quantity;

                      let selectedOptions = "",
                        title = "";

                      let titleOpt = orderItem.title
                        .replace(/\+/g, "")
                        .split("<br/>");
                      let countOpt = Object.keys(titleOpt).length;

                      if (countOpt > 0) {
                        title = titleOpt[0]
                          .replace("<b>", "")
                          .replace("</b>", "");
                        titleOpt.shift();
                        selectedOptions = titleOpt.join(", ");
                      }

                      return (
                        <Grid
                          item
                          key={orderItem.id}
                          direction="row"
                          className={classes.orderItem}
                        >
                          <CardActionArea
                            className={classes.orderItemLabel}
                            onClick={(e) => onOpenProduct(orderItem)}
                          >
                            <div className={classes.titleDiv}>
                              <div className={classes.title}>{title}</div>
                              <hr className={classes.hr} />
                              {(selectedOptions !== "" ||
                                orderItem.comment !== "") && (
                                <div className={classes.options}>
                                  <div>{selectedOptions}</div>
                                  <div>{orderItem.comment}</div>
                                </div>
                              )}
                            </div>

                            {/* <vl className={classes.vlr} /> */}

                            <div className={classes.infoDiv}>
                              <div style={{ marginTop: 8 }}>
                                {user.translate("price")}: {price}{" "}
                                {user.translate("den")}. x {orderItem.quantity}
                              </div>
                              <div>
                                {user.translate("total")}:{" "}
                                {price * orderItem.quantity}{" "}
                                {user.translate("den")}.
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {user.formatDate(orderItem.created, true)}
                              </div>
                              <div style={{ marginBottom: 8 }}>
                                {user.translate("status_" + orderItem.status)}
                              </div>
                            </div>

                            <IconButton
                              className={classes.info}
                              edge="end"
                              color="secondary"
                            >
                              <InfoIcon />
                            </IconButton>
                          </CardActionArea>
                        </Grid>
                      );
                    })}
                  <div className={classes.status}>{user.translate(status)}</div>
                  {/* <Grid item className={classes.total}>{user.translate("total")}: {receipt.total} {user.translate("den")}.</Grid> */}
                  <Grid item className={classes.total}>
                    {user.translate("total")}: {total} {user.translate("den")}.
                  </Grid>
                  {availableQuestioner && (
                    <Box sx={{ mt: 10 }}>
                      <Link
                        to={`/place/${placeId}/questioner/${availableQuestioner?.id}?receiptId=${questionerReceipt.id}`}
                        key={availableQuestioner?.id}
                      >
                        <Button
                          startIcon={<QuizIcon />}
                          variant="outlined"
                          color="primary"
                        >
                          {user.translate("share_your_feedback")}
                        </Button>
                      </Link>
                    </Box>
                  )}
                </>
              );
            } else {
              //orders
              return (
                <>
                  <div className={classes.dateCreatedOrders}>
                    <span>{user.formatDate(receipt.created)}</span>
                    {receipt.status !== "CLOSE" && (
                      <span className={classes.statusOrders}>
                        {user.translate(status)}
                      </span>
                    )}
                    {receipt.status === "CLOSE" && (
                      <span>
                        {user.translate("waiter")}: {receipt.employee}
                      </span>
                    )}
                  </div>

                  <Grid
                    item
                    key={receipt.id}
                    direction="row"
                    className={`${classes.orderItem} ${classes.orderItemOrders}`}
                  >
                    {receipt.orders &&
                      receipt.orders.map((orderItem) => {
                        let price = Math.round(orderItem.price);
                        let canceled =
                          orderItem.status === "CANCELED" ||
                          receipt.status !== "CLOSE";

                        let selectedOptions = "",
                          title = "";

                        let titleOpt = orderItem.title
                          .replace(/\+/g, "")
                          .split("<br/>");
                        let countOpt = Object.keys(titleOpt).length;

                        if (countOpt > 0) {
                          title = titleOpt[0]
                            .replace("<b>", "")
                            .replace("</b>", "");
                          titleOpt.shift();
                          selectedOptions = titleOpt.join(", ");
                        }

                        return (
                          <CardActionArea
                            className={`${classes.orderItemLabel} ${classes.orderItemLabelOrders}`}
                            onClick={(e) => onOpenProduct(orderItem)}
                          >
                            <div
                              className={`${classes.titleDiv} ${classes.titleDivOrders}`}
                            >
                              <div
                                className={`${classes.title} ${
                                  classes.titleOrders
                                } ${
                                  canceled ? classes.titleOrdersCanceled : ""
                                }`}
                              >
                                {title}
                              </div>
                              {/* <hr className={classes.hr} /> */}
                              {(selectedOptions !== "" ||
                                orderItem.comment !== "") && (
                                <div className={classes.options}>
                                  <div>{selectedOptions}</div>
                                  <div>{orderItem.comment}</div>
                                </div>
                              )}
                            </div>

                            <div
                              className={`${classes.infoDiv} ${classes.infoDivOrders}`}
                            >
                              <IconButton
                                className={classes.infoOrders}
                                edge="end"
                                color="secondary"
                              >
                                <InfoIcon />
                              </IconButton>
                              <div style={{ marginTop: 8, marginRight: 8 }}>
                                {user.translate("price")}: {price}{" "}
                                {user.translate("den")}. x {orderItem.quantity}{" "}
                                <br />
                                {user.translate("total")}:{" "}
                                {price * orderItem.quantity}{" "}
                                {user.translate("den")}.
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {user.formatDate(orderItem.created, true)}{" "}
                                <br />
                                {receipt.status === "CLOSE" && (
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color: canceled ? "#e3c178" : "inherit",
                                    }}
                                  >
                                    {user.translate(
                                      "status_" + orderItem.status
                                    )}
                                  </span>
                                )}
                              </div>
                              <IconButton
                                className={classes.canceled}
                                edge="end"
                                color={canceled ? "secondary" : "primary"}
                              >
                                {canceled ? <CloseIcon /> : <CheckIcon />}
                              </IconButton>
                            </div>
                          </CardActionArea>
                        );
                      })}
                  </Grid>

                  <Grid item className={classes.total}>
                    {user.translate("total")}: {receipt.total}{" "}
                    {user.translate("den")}.
                  </Grid>
                </>
              );
            }
          })}
          {loading && <CircularProgress />}
          {!loading && orderResult?.userMenu_orders?.error && (
            <div>Error: {orderResult.userMenu_orders.error}</div>
          )}
          {/* {
						!finished && !loading && orderResult != undefined && orderResult.userMenu_orders.message != undefined && (<div>{orderResult.userMenu_orders.message}</div>)
					} */}
        </Grid>

        <p></p>

        {selectedProduct !== false && (
          <ProductDialog
            item={selectedProduct}
            open={selectedProduct !== false}
            redirect={`/place/${placeId}/menu`}
            onClose={onProductClose}
          />
        )}
      </div>
    </div>
  );
}

Orders.propTypes = {
  cart: PropTypes.any.isRequired,
};
