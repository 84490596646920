import React, { useContext, useEffect, useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Container,
  CircularProgress,
  Grid,
  IconButton,
  Avatar,
  Typography,
  SpeedDial,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import helper from "../../common/Helper";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RestaurantView from "./RestaurantView";
import { UserContext } from "../App";
import HomeNavigation from "./HomeNavigation";
import QrIcon from "../../icons/qr_code_64.png";

const useStyles = makeStyles((theme) => ({
  resend_mail: {
    cursor: "pointer",
  },
  root: {
    // background: `url('${process.env.PUBLIC_URL}/images/background.webp')`,
    // backgroundRepeat: 'round',
    // backgroundSize: 'cover',
    minHeight: "100vh",
    marginTop: theme.spacing(2),
  },
  content: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 420,
  },
  contentGrid: {
    display: "flex",
    height: "100%",
    maxWidth: 420,
    animation: "$slideUp 0.3s",
    animationFillMode: "both",
  },
  speedDial: {
    position: "fixed",
    right: 0,
    bottom: 0,
    margin: 16,
  },
  selectRestaurant: {
    display: "flex",
    width: "100%",
    whiteSpace: "nowrap",
  },
  hr: {
    "&::before": {
      background: "#333",
      // content: 'url(/favicon-16x16.png)',
      padding: "0 4px",
      position: "relative",
      top: -13,
    },
    border: "none",
    borderTop: "3px double #e3e3e3",
    color: "#e3e3e3",
    overflow: "visible",
    textAlign: "center",
    height: 5,
    width: "100%",
    borderRadius: "100%",
    marginBottom: 23,
  },
  "@keyframes slideUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  codeScanner: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // background: `url('${process.env.PUBLIC_URL}/images/background.webp')`,
    // backgroundRepeat: 'round',
    // backgroundSize: 'cover',
    zIndex: 999999,
    animation: "$slideUp 0.5s",
  },
  restaurantItem: {
    marginBottom: 25,
    width: "100%",
  },
  buttonCloseScan: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 16,
    zIndex: 99999,
  },
}));

export default function Home(props) {
  const classes = useStyles();

  const user = useContext(UserContext);
  const history = useHistory();
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [qrCode, setQrCode] = useState({
    code: undefined,
    error: undefined,
  });
  const scannedCodeRef = useRef();

  const [search, setSearch] = useState("");

  const onSearch = (e) => {
    let search = e === "" ? "" : e.target.value;
    setSearch(search);

    helper.searchRestaurants(search, props.restaurants);
  };

  const [scanCode, { loading: scanCodeLoading, data: scanCodeResult }] =
    useMutation(gql`
      mutation ($code: String!) {
        userMenu_useQR(code: $code) {
          message
          error
          placeId
        }
      }
    `);

  const onScanQrClick = (e) => {
    e.stopPropagation();
    setShowQrScanner(true);
  };

  const onScanError = (err) => {
    setQrCode({ error: err });

    onScanClose();
  };

  const onScanClose = (e) => {
    setShowQrScanner(false);
  };

  const onCodeScaned = (result, error) => {
    if (error) {
      return false;
      //   return onScanError(error);
    }
    console.log("Code scanned", result);

    const scannedCode = result?.text;

    if (
      scannedCode == null ||
      scanCodeLoading ||
      (scannedCodeRef.current?.code === scannedCode &&
        Date.now() - scannedCodeRef.current.time < 1200)
    ) {
      return;
    }
    scannedCodeRef.current = { code: scannedCode, time: Date.now() };

    var code = scannedCode.match("/qr/([0-9]+)");
    if (code == null || code.length < 2) {
      code = scannedCode.match("q\\..+\\.mk/([0-9]+)");
    }

    if (code == null || code.length < 2) {
      setQrCode({
        code: scannedCode,
        error: user.translates("invalid, ,code"),
      });
      return;
    }

    setQrCode({ code: scannedCode, error: undefined });

    scanCode({
      variables: {
        code: code[1],
      },
    });
  };

  useEffect(() => {
    if (
      scanCodeLoading === false &&
      scanCodeResult !== undefined &&
      scanCodeResult.userMenu_useQR.placeId !== undefined
    ) {
      const { placeId } = scanCodeResult.userMenu_useQR;
      if (placeId) {
        history.push(`/place/${placeId}/menu`);
      }
    }
  }, [history, scanCodeLoading, scanCodeResult]);

  return (
    <Container component="main" className={classes.root}>
      <HomeNavigation
        onSetLanguage={props.setLanguage}
        search={search}
        onSearch={onSearch}
        refetch={props.refetch}
      >
        <div className={classes.content}>
          <div className={classes.selectRestaurant}>
            <Typography variant="overline">
              {user.translate("select_restaurant").toLowerCase()}
            </Typography>
          </div>
          <hr className={classes.hr} />

          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.contentGrid}
          >
            {props.restaurants != null ? (
              props.restaurants.map((restaurant) => (
                <Grid
                  item
                  key={`rest_${restaurant.id}`}
                  className={`${classes.restaurantItem} rest_${restaurant.id}`}
                >
                  <RestaurantView restaurant={restaurant} />
                </Grid>
              ))
            ) : (
              <CircularProgress />
            )}
          </Grid>

          <SpeedDial
            ariaLabel="Scan QR"
            className={classes.speedDial}
            open={false}
            onClick={(e) => onScanQrClick(e)}
            icon={
              <img
                alt="Scan QR"
                src={QrIcon}
                width={28}
                height={28}
                style={{ filter: "invert(95%)" }}
              />
            }
          />

          {(showQrScanner || scanCodeLoading) && (
            <div className={`${classes.codeScanner} gradient`}>
              <IconButton
                className={classes.buttonCloseScan}
                color="inherit"
                onClick={onScanClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              {showQrScanner && (
                <QrReader
                  scanDelay={300}
                  onError={onScanError}
                  onResult={onCodeScaned}
                  constraints={{
                    facingMode: { ideal: "environment" },
                  }}
                  containerStyle={{
                    width: "100%",
                    background: "rgb(3 3 3 / 44%)",
                    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 20px 15px",
                  }}
                  videoStyle={{
                    opacity: scanCodeLoading ? 0.5 : 1,
                  }}
                />
              )}
              <CircularProgress
                style={{
                  opacity: scanCodeLoading ? 1 : 0,
                  position: "absolute",
                }}
              />
              <p style={{ height: 30, marginTop: 32 }}>
                {qrCode.error ?? scanCodeResult?.userMenu_useQR?.error ?? " "}
              </p>
            </div>
          )}
        </div>
      </HomeNavigation>
    </Container>
  );
}
