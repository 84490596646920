import { GoogleLogin } from "@react-oauth/google";
import { useContext, useState } from "react";
import { UserContext } from "views/App";
import LoadingBox from "../common/LoadingBox";
import useLoginWithGoogleToken from "./useLoginWithGoogle";
import useOnLogin from "../common/useOnLogin";

// React Function Component
export default function LoginGoogle(props) {
  const user = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const onLogin = useOnLogin(props);

  const { loginWithGoogleToken, loading } = useLoginWithGoogleToken(onLogin);

  return (
    <LoadingBox
      loading={loading || isLoading}
      sx={{ pb: 1.5 }}
      bottomGuther={1}
    >
      <GoogleLogin
        cookiePolicy="single_host_origin"
        onSuccess={(credentialResponse) => {
          setIsLoading(false);
          loginWithGoogleToken(credentialResponse.credential);
        }}
        onError={() => {
          setIsLoading(false);
          console.log("Login Failed");
        }}
        responseType="id_token"
        logo_alignment="left"
        locale={user.lang}
        onLogin={() => {
          setIsLoading(true);
        }}
        width={320}
      />
    </LoadingBox>
  );
}
