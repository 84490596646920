import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import {
    InputLabel,
    Input,
    MenuItem,
    FormControl,
    FormGroup,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    TextField,
    Select,
    Checkbox,
    Radio,
    RadioGroup,
    InputAdornment,
    Grid,
    IconButton,
    Button,
    Autocomplete
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    LocalizationProvider,
    DateTimePicker,
    DatePicker,
} from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UserContext } from "../views/App";
import helper from './Helper';
import config from '../config';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        clear: `both`,
    },
    formControlButton: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        clear: `both`,
        float: "right",
    },
    formControlLine: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 176,
        display: `flex`,
        float: `left`,
    },
    textField: {
        // marginTop: theme.spacing(2),
        '& .MuiInputBase-root.MuiInput-root': {
            '&:before': {
                borderBottom: '1px solid #e3e3e3',
            },
            '&:hover': {
                '&:before': {
                    borderBottom: '2px solid #e3e3e3',
                }
            }
        },
    },
    inputHidden: {
        margin: 0,
        padding: 0,
        border: 0,
        width: `0%`,
        whiteSpace: 'nowrap',
        visibility: `hidden`,
        display: `block`,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectHeight: {
        maxHeight: 196
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    helperText: {
        fontStyle: "italic"
    },
    input: {
        display: 'none',
    },
    formControlUpload: {
        marginTop: theme.spacing(2),
        minWidth: 120,
        clear: `both`,
        display: `inline-block`,
        position: 'relative',
    },
    image: {
        maxWidth: 150,
        maxHeight: 150,
        float: `left`,
        marginRight: theme.spacing(3)
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    labelUpload: {
        "@media screen and (max-width: 402px)": {
            marginTop: theme.spacing(2),
            display: "inline-block"
        },
    },
}));

function NumberFormatCustom(props) {

    const user = useContext(UserContext);

    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onKeyDown={(event) => {
                switch (event.key) {
                    case 'ArrowDown':
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") - 1,
                            },
                        });
                        break;
                    case 'ArrowUp':
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") + 1,
                            },
                        });
                        break;
                    default:
                        break;
                }
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={user.restLang === 'mk' ? '.' : ','}
            decimalSeparator={user.restLang === 'mk' ? ',' : '.'}
            decimalScale={3}
            isNumericString
        // prefix={props.name === 'price' || props.name === 'amount' ? user.icu.getCurrencySymbol() : undefined}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function Form(props) {

    const classes = useStyles();
    const user = useContext(UserContext);

    let fieldList = { ...props.fieldList };
    let fieldItem = { ...props.fieldList[props.item] };

    const [value, setValue] = useState(fieldItem.fieldValue);

    const data = new FormData();

    function StyledRadio(props) {
        const classes = useStyles();

        return (
            <Radio
                className={classes.root}
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
            />
        );
    }

    const [errorObj, setErrorObj] = useState({
        error: fieldItem.error ? fieldItem.error : false,
        // helperText: ""
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleDateChange = (date) => {

        if (date != `Invalid Date` && fieldItem.fieldValue !== date) {
            setValue(date);
            setErrorObj({
                error: false,
                // helperText: ""
            });

            if (fieldItem.formatDate)
                props.setItemsFunction(fieldItem.fieldName, user.formatDate(date, false, "yyyy-MM-DD"));
            else props.setItemsFunction(fieldItem.fieldName, new Date(date).getTime());

            fieldList[props.item] = { ...fieldItem, fieldValue: date, error: false };
            props.setStateObj({ ...fieldList });
        } else {
            fieldList[props.item] = { ...fieldItem, fieldValue: fieldItem.fieldValue, error: fieldItem.required ? true : undefined };
            props.setStateObj({ ...fieldList });
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const uploadFile = (fieldItem, event) => {

        data.append('file', event.target.files[0])

        axios.post(`${config.apiUrl}/uploadImage`, data, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: 'include'
        }).then(response => { // then print response status
            user.consoleLog(response.data[0])
            fieldItem.fieldValue = response.data[0];

            fieldList[props.item] = fieldItem;
            props.setStateObj({ ...fieldList });

            setValue(response.data[0]);

            props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue);
        }).catch(error => {
            if (error.response) {
                user.consoleLog(error.response);
            }
            user.consoleLog(error.message);

            fieldList[props.item] = { ...fieldItem, fieldValue: "", error: fieldItem.required ? true : undefined };
            props.setStateObj({ ...fieldList });
        });
    }

    const filterOptions = (options, params) => options.filter(option =>
        option.label.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1
    );

    const checkErrorsFunctionAutoselect = (fieldItem, value) => {

        if (value !== null) {
            if (typeof value.inputValue !== "undefined" && value.inputValue !== "") {//add new autocompleteAdd
                fieldItem.fieldValue = {
                    val: value.inputValue,
                    label: value.inputValue
                };

                if (fieldItem.wholeValueId === true) {
                    let fieldId = fieldItem.fieldName
                    let fieldName = fieldId.replace("Id", "Name");
                    props.setItemsFunction([fieldId, fieldName], [helper.uid(), value.inputValue], true);
                }
                else props.setItemsFunction(fieldItem.fieldName, helper.uid());
            }
            else if (typeof value.val !== "undefined" && value.val !== "") {
                fieldItem.fieldValue = value;

                if (fieldItem.wholeValueId === true) {
                    let fieldId = fieldItem.fieldName;
                    let fieldName = fieldId.replace("Id", "Name");
                    props.setItemsFunction([fieldId, fieldName], [value.val, value.label]);
                }
                else if (fieldItem.fieldName === "stockType") {
                    props.setItemsFunction(["stockType", "uom"], [value.val, value.label.indexOf(user.translate("piece")) > -1 ? 2 : 0]);
                }
                else props.setItemsFunction(fieldItem.fieldName, value.val);
            }
            fieldList[props.item] = { ...fieldItem, fieldValue: fieldItem.fieldValue, error: false };
        }
        else {
            fieldList[props.item] = { ...fieldItem, fieldValue: { val: "", label: "" }, error: fieldItem.required ? true : undefined };
            props.setItemsFunction(fieldItem.fieldName, "");
        }

        props.setStateObj({ ...fieldList });
    };

    const checkErrorsFunction = (fieldItem, event) => {

        let error = false;

        setValue(event.target.value)

        fieldItem.fieldValue = event.target.value;

        if (event.target.required && event.target.value === "") {
            fieldItem.error = true;
            error = true;

            setErrorObj({
                error: true,
                // helperText: "Please fill out this field"
            });
        }

        if (event.target.type === 'email') {
            let re = /\S+@\S+\.\S+/;

            if (event.target.value !== "" && !re.test(String(event.target.value).toLowerCase())) {
                fieldItem.error = true;
                error = true;

                setErrorObj({
                    error: true,
                    // helperText: "Please enter a valid email address"
                });
            }
        }

        if (!error) {
            fieldItem.error = false;
            fieldItem.helperText = "";

            setErrorObj({
                error: false,
                // helperText: ""
            });
        }

        props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue);
        fieldList[props.item] = fieldItem;
        props.setStateObj({ ...fieldList });
    }

    const changeColor = (fieldItem, color) => {

        setValue(color)

        props.setItemsFunction(color, fieldItem.fieldName, "#" + color.hex);

        fieldItem.fieldValue = "#" + color.hex;
        fieldList[props.item] = fieldItem;
        props.setStateObj({ ...fieldList });
    }

    const renderForm = (fieldItem) => {

        if (typeof fieldItem !== "undefined") {

            let labelId = "label_" + fieldItem.fieldLabel;
            let fieldId = "id_" + fieldItem.fieldName;

            if (fieldItem.fieldType === 'text') {
                if (fieldItem.type !== "hidden") {
                    if (fieldItem.type === "password_show") {

                        return (
                            <div>
                                <TextField
                                    required={fieldItem.required}
                                    type={showPassword ? 'text' : 'password'}
                                    className={classes.textField}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    fullWidth
                                    defaultValue={fieldItem.fieldValue}
                                    onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                    onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    variant="standard"
                                    // helperText={errorObj.helperText}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    position="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        )
                    } else if (fieldItem.type === 'number') {
                        return (
                            <FormGroup className={props.line ? classes.formControlLine : classes.formControl}>
                                <FormControl className={!props.line ? classes.formControl : ""}>
                                    <TextField
                                        required={fieldItem.required}
                                        name={fieldItem.fieldName}
                                        // type={fieldItem.type}
                                        label={fieldItem.fieldLabel}
                                        fullWidth
                                        value={fieldItem.fieldValue}
                                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                        // onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                        error={errorObj.error}
                                        helperText={fieldItem.helperText || undefined}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                            autoComplete: 'off'
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>
                            </FormGroup>
                        )
                    } else {
                        return (
                            <FormGroup className={props.product && fieldItem.type === "select" || fieldItem.line ? classes.formControlLine : classes.formControl}>
                                <FormControl className={!props.line ? classes.formControl : ""}>
                                    <TextField
                                        required={fieldItem.required}
                                        type={fieldItem.type}
                                        className={classes.textField}
                                        disabled={fieldItem.type === "disabled" ? true : undefined}
                                        name={fieldItem.fieldName}
                                        label={fieldItem.fieldLabel}
                                        fullWidth
                                        defaultValue={fieldItem.fieldValue}
                                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                        onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                        error={errorObj.error}
                                        helperText={fieldItem.helperText || undefined}
                                        variant="standard"
                                    />
                                </FormControl>
                            </FormGroup>
                        )
                    }
                }
                else {
                    return (
                        <div>
                            <Input
                                required={fieldItem.required}
                                type={fieldItem.type}
                                className={classes.inputHidden}
                                name={fieldItem.fieldName}
                                label={fieldItem.fieldLabel}
                                fullWidth
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                error={errorObj.error}
                                variant="standard"
                            // helperText={errorObj.helperText}
                            />
                        </div>
                    )
                }
            }
            else if (fieldItem.fieldType === 'textarea') {
                return (
                    <div>
                        <TextField
                            required={fieldItem.required}
                            type={fieldItem.type}
                            name={fieldItem.fieldName}
                            className={classes.textField}
                            id={fieldId}
                            label={fieldItem.fieldLabel}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            defaultValue={fieldItem.fieldValue}
                            onChange={(event) => checkErrorsFunction(fieldItem, event)}
                            onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                            error={errorObj.error}
                        // helperText={errorObj.helperText}
                        />

                    </div>
                )
            }
            else if (fieldItem.fieldType === 'select') {
                return (
                    <FormGroup className={props.line ? classes.formControlLine : ""}>
                        <FormControl className={!props.line ? classes.formControl : ""}>
                            <InputLabel id={labelId} required={fieldItem.required}>{fieldItem.fieldLabel}</InputLabel>
                            <Select
                                labelId={labelId}
                                id={fieldId}
                                name={fieldItem.fieldName}
                                fullWidth
                                MenuProps={{ classes: { paper: classes.selectHeight } }}
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                            >
                                {
                                    typeof fieldItem.noNone === "undefined" && (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )
                                }

                                {fieldItem.fieldOptions.map(val => val.label !== "" && (
                                    <MenuItem key={val.val} value={val.val}>
                                        {val.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                        </FormControl>
                    </FormGroup>
                )
            }
            else if (fieldItem.fieldType === 'autocomplete') {
                return (
                    <>
                        <FormGroup className={props.line ? classes.formControlLine : ""}>
                            <FormControl className={!props.line ? classes.formControl : ""}>
                                <Autocomplete
                                    id={fieldId}
                                    name={fieldItem.fieldName}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    selectOnFocus
                                    handleHomeEndKeys
                                    options={fieldItem.fieldOptions}
                                    value={fieldItem.fieldValue}
                                    filterOptions={filterOptions}
                                    onChange={(event, value) => checkErrorsFunctionAutoselect(fieldItem, value)}
                                    getOptionSelected={(option, value) => option.label === value.label}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => {
                                        params.inputProps.autoComplete = "off";
                                        return <TextField
                                            {...params}
                                            label={fieldItem.fieldLabel}
                                            required={fieldItem.required}
                                            error={fieldItem.error}
                                        />
                                    }}
                                />
                            </FormControl>
                        </FormGroup>
                    </>
                )
            }
            else if (fieldItem.fieldType === 'autocompleteAdd') {
                return (
                    <FormGroup className={props.line ? classes.formControlLine : ""}>
                        <FormControl className={!props.line ? classes.formControl : ""}>
                            <Autocomplete
                                id={fieldId}
                                name={fieldItem.fieldName}
                                fullWidth
                                autoHighlight
                                freeSolo
                                selectOnFocus
                                handleHomeEndKeys
                                options={fieldItem.fieldOptions}
                                value={fieldItem.fieldValue}
                                onChange={(event, value) => checkErrorsFunctionAutoselect(fieldItem, value)}
                                getOptionSelected={(option, value) => option.label === value.label}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.label;
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            label: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                renderOption={(option) => option.label}
                                renderInput={(params) => {
                                    params.inputProps.autoComplete = "off";
                                    return <TextField
                                        {...params}
                                        label={fieldItem.fieldLabel}
                                        required={fieldItem.required}
                                        error={fieldItem.error}
                                    />
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                )
            }
            else if (fieldItem.fieldType === 'checkbox') {
                return (
                    <FormGroup row className={classes.formControl}>
                        <FormLabel component="legend">{fieldItem.fieldLabel}</FormLabel>
                        {fieldItem.fieldOptions.map((val, indexx) => (
                            <FormControlLabel
                                key={"checkbox_" + indexx}
                                control={
                                    <Checkbox
                                        required={fieldItem.required}
                                        name={fieldItem.fieldName}
                                        color="primary"
                                        fullWidth
                                        defaultValue={fieldItem.fieldValue}
                                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                        onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                        error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                    />
                                }
                                label={val.label}
                            />
                        ))}
                    </FormGroup>
                )
            }
            else if (fieldItem.fieldType === 'radio') {
                return (
                    <FormGroup row className={classes.formControl}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">{fieldItem.fieldLabel}</FormLabel>
                            <RadioGroup
                                row
                                required={fieldItem.required}
                                aria-label={fieldItem.fieldName}
                                name={fieldItem.fieldName}
                                defaultValue={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                            // helperText={errorObj.helperText}
                            >
                                {fieldItem.fieldOptions.map((val, indexx) => (
                                    <FormControlLabel key={"radio_" + indexx} value={val.val} control={<StyledRadio />} label={val.label} />
                                ))}
                            </RadioGroup>
                            {/*<FormHelperText>Choose wisely helper text</FormHelperText>*/}
                        </FormControl>
                    </FormGroup>
                )
            }
            else if (fieldItem.fieldType === 'date' && fieldItem.type !== "hidden") {
                return (
                    <div className={classes.formControl}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.formControl}>
                            <Grid container justifyContent="flex-start">
                                <DatePicker
                                    disableToolbar={fieldItem.type === "dateyear" ? false : true}
                                    autoOk
                                    format={fieldItem.fieldFormat ? fieldItem.fieldFormat : user.dateFormat}
                                    required={fieldItem.required}
                                    name={fieldItem.fieldName}
                                    className={classes.textField}
                                    id={fieldId}
                                    value={value}
                                    disableFuture
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    error={errorObj.error}
                                    label={fieldItem.fieldLabel}
                                    renderInput={(props) => <TextField {...props} variant="standard" className={classes.textField}/>} // helperText={errorObj.helperText}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </div>
                )
            }
            else if (fieldItem.fieldType === 'datetime' && fieldItem.type !== "hidden") {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.formControl}>
                        <Grid container justifyContent="flex-start">
                            <DateTimePicker
                                disableToolbar={fieldItem.type === "dateyear" ? false : true}
                                autoOk
                                format={fieldItem.fieldFormat ? fieldItem.fieldFormat : user.dateTimeFormat}
                                required={fieldItem.required}
                                name={fieldItem.fieldName}
                                className={classes.textField}
                                id={fieldId}
                                value={value}
                                disableFuture
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={errorObj.error}
                                label={fieldItem.fieldLabel}
                                renderInput={(props) => <TextField {...props} variant="standard" className={classes.textField}/>} // helperText={errorObj.helperText}
                            />
                        </Grid>
                    </LocalizationProvider>
                )
            }
            else if (fieldItem.fieldType === 'upload' && fieldItem.type !== "hidden") {

                return (
                    <div className={classes.formControlUpload}>
                        <Grid item className={classes.image}>
                            <img className={classes.img} alt={user.translate("upload_image")} src={`${value !== null && value.indexOf("http") === - 1 ? config.apiImagesUrl + "/" : ""}${value}`} />
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            name={fieldItem.fieldName}
                            onChange={(event) => uploadFile(fieldItem, event)}
                            onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                        />
                        <label htmlFor="contained-button-file" className={classes.labelUpload}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                {user.translate("upload")}
                            </Button>
                        </label>
                    </div>
                )
            }
        }

    }

    return renderForm(fieldItem);
}