import { Box, Container, CssBaseline, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";

function Copyright() {
  return (
    <Box position="fixed" bottom={0} left={0} right={0} p={1}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" to="/">
          E-Bar
        </Link>{" "}
        {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
  },
  paper: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent", // theme.palette.secondary.main,
    width: 72,
    height: 72,
    borderRadius: "unset",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  error: {
    color: "red",
  },
  "@keyframes slideUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  bottomOptions: {
    animation: "$slideUp 0.3s",
    animationFillMode: "both",
    animationDelay: 100,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
    "@media screen and (max-width: 375px)": {
      flexDirection: "column",
    },
  },
}));

export default function UserAuthForm(props) {
  const classes = useStyles();

  const user = useContext(UserContext);

  const [formState, setFormState] = useState({
    isLoading: false,
    error: null,
  });

  const loginMethods = useMemo(() => {
    const formProps = {
      ...formState,
      onStartLoading: () => setFormState({ isLoading: true, error: null }),
      onError: (error) => setFormState({ isLoading: false, error }),
      onLogin: () => {
        setFormState({ isLoading: false, error: false });
        return props.onLogin?.();
      },
      refreshOnLogin: props.refreshOnLogin,
    };
    return React.Children.map(props.children, (child) =>
      React.cloneElement(child, {
        ...child.props,
        ...formProps,
      })
    );
  }, [formState, props]);

  if (props.mini) {
    return (
      <Stack
        paddingTop={1}
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Typography variant="h6">
          {React.isValidElement(props.title)
            ? props.title
            : user.translate(props.title)}
        </Typography>
        <form className={classes.form} noValidate>
          <Stack spacing={1} direction="column" alignItems="center">
            {loginMethods}
            {formState.error && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <p className={classes.error}>{formState.error}</p>
              </Box>
            )}
          </Stack>
        </form>
      </Stack>
    );
  }
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <Stack
        paddingTop={4}
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <img
          src={`${process.env.PUBLIC_URL}/android-icon-144x144.png`}
          alt={user.translate("e_bar")}
          width="72px"
          style={{ filter: "drop-shadow(2px 2px 12px #000)" }}
        />

        <Typography component="h1" variant="h5">
          {React.isValidElement(props.title)
            ? props.title
            : user.translate(props.title)}
        </Typography>
        <form className={classes.form} noValidate>
          <Stack spacing={1} direction="column" alignItems="center">
            {loginMethods}
            {formState.error && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <p className={classes.error}>{formState.error}</p>
              </Box>
            )}
          </Stack>
        </form>
      </Stack>
      <Copyright />
    </Container>
  );
}
