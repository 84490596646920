import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useRedirectLocationFromUrl = () => {
  const location = useLocation();

  return useMemo(() => {
    let locationHash = location.hash.replace("#", "");

    const path = new URLSearchParams(location.search);

    path.forEach(function (value, key) {
      if (key === "state") {
        locationHash = value;
      }
    });

    return locationHash;
  }, [location.hash, location.search]);
};
