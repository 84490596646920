import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ThemeProvider } from "@emotion/react";
import "@fontsource/roboto";
import {
  StyledEngineProvider,
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
} from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/styles";
import "core-js";
import "core-js/es/global-this";
import "firebase/analytics";
import React from "react";
import ReactDOM from "react-dom";
import { FacebookProvider } from "react-facebook";
import { BrowserRouter as Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import config from "./config";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./views/App";
import { GoogleOAuthProvider } from "@react-oauth/google";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
  config.isTest !== true
    ? {
        apiKey: "AIzaSyA_BIQOOLionA0kPIf46G70ScL2PHPVhFo",
        authDomain: "e-bar-e4f6f.firebaseapp.com",
        databaseURL: "https://e-bar-e4f6f.firebaseio.com",
        projectId: "e-bar-e4f6f",
        storageBucket: "e-bar-e4f6f.appspot.com",
        messagingSenderId: "941778011187",
        appId: "1:941778011187:web:70d360a86ff007e1fd0f46",
        measurementId: "G-ZJ36TF12T0",
      }
    : {
        apiKey: "AIzaSyA_BIQOOLionA0kPIf46G70ScL2PHPVhFo",
        authDomain: "e-bar-e4f6f.firebaseapp.com",
        databaseURL: "https://e-bar-e4f6f.firebaseio.com",
        projectId: "e-bar-e4f6f",
        storageBucket: "e-bar-e4f6f.appspot.com",
        messagingSenderId: "941778011187",
        appId: "1:941778011187:web:272313af70ee9d8efd0f46",
        measurementId: "G-RLFRKFXF7J",
      };

Bugsnag.start({
  apiKey: "1a6ff7fb36b127224ab789f39fe8993a",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const transitionCyrLat = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  ѓ: "g",
  е: "e",
  ж: "z",
  з: "z",
  ѕ: "z",
  и: "i",
  ј: "j",
  к: "k",
  л: "l",
  љ: "l",
  м: "m",
  н: "n",
  њ: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  ќ: "k",
  у: "u",
  ф: "f",
  х: "h",
  ц: "c",
  ч: "c",
  џ: "dz",
  ш: "s",
  lj: "l",
  sh: "s",
  nj: "n",
  gj: "g",
};
const Str = String;
Str.prototype.cirilicToLatin = function () {
  let self = this.toLowerCase();
  for (const letter in transitionCyrLat) {
    self = self.replace(new RegExp(letter, "g"), transitionCyrLat[letter]);
  }
  return self;
};
Str.prototype.cirilicLatinCompare = function (lang) {
  let self = this.toLowerCase();
  if (lang !== "mk" && lang !== "MK") {
    for (let letter in transitionCyrLat) {
      self = self.replace(new RegExp(letter, "g"), transitionCyrLat[letter]);
    }
  } else {
    for (let letter in transitionCyrLat) {
      self = self.replace(new RegExp(transitionCyrLat[letter], "g"), letter);
    }
  }

  return self;
};

if (String.prototype.replaceAll === undefined) {
  Str.prototype.replaceAll = function (search, replace) {
    return this.replace(
      new RegExp(search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"),
      replace
    );
  };
}
Str.prototype.capitalize = function (forceCamelCase = true) {
  if (this.length <= 1) return this;
  if (forceCamelCase) {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  } else {
    return this.charAt(0).toUpperCase() + this.slice(1);
  }
};

Str.prototype.capitalizeAll = function () {
  return this.split(/([-\/\\^$*+?.()|[\]{}]+)/)
    .map((w) => w.capitalize())
    .join("");
};
Str.prototype.capitalizeSentence = function () {
  return this.split(/([\.\?\!]\s*)/)
    .map((w) => w.capitalize())
    .join("");
};

Str.prototype.wordsUpTo = function (length) {
  if (this.length <= length) return this;
  var result = "";
  let words = this.split(/([\.\?\!\s]+)/)
    .map((w) => w.capitalize())
    .forEach((word) => {
      if (result.length + word.length <= length) {
        result += word;
      }
    });
  return result.trim();
};

Array.prototype.removeIf = function (callback) {
  var i = this.length;
  while (i--) {
    if (callback(this[i], i)) {
      this.splice(i, 1);
    }
  }
};

Array.prototype.get = function (index, defValue) {
  if (this.length == 0) return defValue;
  if (index < 0 || index >= this.length) return defValue;

  return this[index];
};

Array.prototype.joinOf = function (glue, valFunction, defValue) {
  if (this.length === 0) return defValue || "";
  let result = undefined;
  this.forEach((item) => {
    if (result === undefined) {
      result = valFunction(item);
    } else {
      result += glue + valFunction(item);
    }
  });
  return result || defValue || "";
};

Array.prototype.orderBy = function (keyFunction) {
  return [...this].sort((a, b) => keyFunction(a) - keyFunction(b));
};

Array.prototype.orderByDesc = function (keyFunction) {
  return [...this].sort((a, b) => keyFunction(b) - keyFunction(a));
};

const createTheme =
  config.apiUrl.indexOf("dev") > -1
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

const theme = createTheme({
  palette: {
    type: "dark",
    text: {
      primary: "#e3e3e3",
      secondary: "#f3f3f3", // #f3f3f3
      disabled: "rgba(243,243,243,0.5)",
    },
    background: {
      default: "#474847",
      paper: "#474847",
    },
    primary: {
      light: "rgba(68, 172, 105, 0.2)", // will be calculated from palette.primary.main,
      medium: "rgba(68, 172, 105, 0.7)",
      main: "rgba(68, 172, 105, 0.9)",
      dark: "rgba(68, 172, 105, 1)", // will be calculated from palette.primary.main,
      contrastText: "#5d1a69",
    },
    secondary: {
      light: "#ad9149",
      border: "rgba(173, 145, 73, 0.8)",
      main: "#e3c178", // '#b75cb7',
      dark: "#775437",
      contrastText: "#471a4f",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const cache = new InMemoryCache({
  typePolicies: {
    WebMenuCategory: {
      keyFields: ["id", "name"],
    },
    WebMenuItem: {
      keyFields: ["id", "name"],
    },
    WebMenuItemGroup: {
      keyFields: ["id", "name"],
    },
    WebMenuItemGroupItem: {
      keyFields: ["id", "name"],
    },
  },
});
const link = new HttpLink({
  uri: `${config.apiUrl}/graphql/userMenu`,
  credentials: "include",
  withCredentials: true,
  fetchOptions: {
    credentials: "include",
  },
});

const client = new ApolloClient({
  cache,
  link,
});

// ReactDOM.render(<ThemeProvider theme={theme}><ApolloProvider client={client}><App /></ApolloProvider></ThemeProvider>, document.getElementById('root'));
ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <StyledEngineProvider injectFirst={true}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <FacebookProvider appId={config.fbAppId}>
                <GoogleOAuthProvider clientId={config.googleClientId}>
                  <ApolloProvider client={client}>
                    <Router>
                      <App />
                    </Router>
                  </ApolloProvider>
                </GoogleOAuthProvider>
              </FacebookProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </FirebaseAppProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
