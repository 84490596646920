import { gql, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import helper from "common/Helper";
import { UserContext } from "../../App";
import LoadingBox from "../common/LoadingBox";
import { useStyles } from "./styles";
import { useRedirectLocationFromUrl } from "../common/useRedirectLocationFromUrl";

export const PasswordForgot = (props) => {
  const classes = useStyles();

  const user = useContext(UserContext);

  const history = useHistory();

  const redirectUri = useRedirectLocationFromUrl();

  const [phoneOrEmail, setPhoneOrEmail] = useState("");

  const FORGOT_PASS = gql`
    mutation ($email: String!, $phone: String!) {
      auth_requestResetPassword(
        email: $email
        phone: $phone
        platform: WEB_MENU
      ) {
        message
        ok
        verifier
      }
    }
  `;

  const [forgotPass, { loading }] = useMutation(FORGOT_PASS);

  const checkForgotFields = () => {
    if (loading) return true;
    if (phoneOrEmail !== "") {
      let regEmail = /\S+@\S+\.\S+/;
      let regPhone = /^\+?[0-9]+$/g;

      if (
        !regEmail.test(String(phoneOrEmail).toLowerCase()) &&
        !regPhone.test(String(phoneOrEmail))
      ) {
        return true;
      }

      return false;
    }

    return true;
  };

  const forgot = async (e) => {
    e.preventDefault();

    props.onStartLoading();

    try {
      const variables =
        phoneOrEmail.indexOf("@") > -1
          ? {
              email: phoneOrEmail,
              phone: "",
            }
          : {
              email: "",
              phone: helper.getPhone(phoneOrEmail),
            };

      const { data, errors } = await forgotPass({ variables });

      if (data) {
        if (data.auth_requestResetPassword.ok) {
          if (data.auth_requestResetPassword.message.length > 0) {
            alert(data.auth_requestResetPassword.message);
          }

          history.push(
            "/reset-pass/" +
              data.auth_requestResetPassword.verifier +
              "#" +
              redirectUri
          );
        } else {
          props.onError(data.auth_requestResetPassword.message);
        }
      } else if (errors != null) {
        props.onError(user.translate("register_error"));
      }
    } catch (e) {
      props.onError(e.message);
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="none"
        required
        fullWidth
        id="phone_or_email"
        label={user.translates("phone, ,or, ,email_address")}
        name="phone_or_email"
        value={phoneOrEmail}
        onChange={(e) => setPhoneOrEmail(e.target.value)}
        autoFocus
      />
      <LoadingBox loading={loading} bottomGuther={1} noFade>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={forgot}
          disabled={checkForgotFields()}
          className={classes.submit}
        >
          {user.translate("send_code")}
        </Button>
      </LoadingBox>
    </>
  );
};
