import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { PlaceContext } from "views/home/Restaurant";
import { UserContext } from "../../App";
import OrederItem, { formatCurrency } from "./OrderItem";
import { calcPrice } from "./utils";

// React Function Component
export const OrdersList = ({ items }) => {
  const user = useContext(UserContext);

  const history = useHistory();
  const place = useContext(PlaceContext);

  const goToMenu = useCallback(() => {
    history.push(`/place/${place.place.id}/menu`);
  }, [history, place.place.id]);

  const total = useMemo(
    () => items.reduce((acc, item) => acc + item.quantity * calcPrice(item), 0),
    [items]
  );

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      alignSelf="stretch"
      spacing={2}
      padding={2}
    >
      {items.map((cartItem) => {
        return <OrederItem key={cartItem.id} cartItem={cartItem} />;
      })}

      {total !== 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
        >
          <Button
            variant="text"
            color="secondary"
            startIcon={<ArrowBack />}
            onClick={goToMenu}
          >
            {user.translate("add_one_more")}
          </Button>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography textAlign="right" variant="caption" sx={{ mb: -1 }}>
              {user.translate("total")}
            </Typography>
            <Typography textAlign="right" variant="subtitle1" fontWeight={600}>
              {formatCurrency(total)} {user.translate("den")}
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

OrdersList.propTypes = {
  items: PropTypes.array.isRequired,
};
