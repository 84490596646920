import React, { useContext } from 'react';
import {
	IconButton,
	Typography,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
} from '@mui/material/';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UserContext } from '../App';
import config from '../../config';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		position: 'relative',
		display: 'inline-flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	action: {
		zIndex: 1,
		display: 'inline-flex',
		textAlign: 'left',
		justifyContent: 'flex-start',
	},
	disableAction: {
		cursor: 'default',
	},
	media: {
		width: 42,
		margin: theme.spacing(1),
		padding: theme.spacing(1/2),
		borderRadius: theme.spacing(1),
	},
	delete: {
	},
	forward: {
	},
}));

export default function RestaurantView(props) {
	const history = useHistory();
	const { restaurant } = props;
	const classes = useStyles();

	const user = useContext(UserContext);
	const place = restaurant.places[0];

	const openRestaurant = () => {
		
		if (place != undefined) {
			history.push(`/place/${place.id}/menu`);
		}
	};

	return (
		<Card className={classes.root}>
			<CardActionArea className={classes.action} onClick={openRestaurant}>
				<CardMedia
					className={classes.media}
					component="img"
					alt={restaurant.name}
					style={{ backgroundColor: `${restaurant.imageLogoBg}` }}
					image={restaurant.imageLogo !== null && restaurant.imageLogo.indexOf('http') > -1 ? restaurant.imageLogo : `${config.apiImagesUrl}/${restaurant.imageLogo}`}
					title={place.name || restaurant.name}
				/>
				<CardContent>
					<Typography variant="body2" component="h2">
						{place.name || restaurant.name}
					</Typography>
					<Typography variant="caption" color="textSecondary" component="span">
						{restaurant.address}
					</Typography>
				</CardContent>
			</CardActionArea>
			<IconButton className={classes.forward} color="primary" onClick={openRestaurant}>
				<ArrowForwardIosIcon />
			</IconButton>
		</Card>
	);
}
