import { Password } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "views/App";
import LoginFacebook from "./facebook/LoginFacebook";
import UserAuthForm from "./form/UserAuthForm";
import LoginGoogle from "./google/LoginGoogle";

export default function SignInMini(props) {
  const user = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();

  const openLogin = useCallback(() => {
    history.push(`/login#${location.pathname}`);
  }, [history, location.pathname]);

  return (
    <UserAuthForm title="signin" {...props} mini>
      <LoginGoogle />
      <LoginFacebook />
      <Button
        variant="contained"
        color="success"
        onClick={openLogin}
        sx={{ width: 320 }}
        startIcon={<Password />}
      >
        {user.translate("login_password")}
      </Button>
    </UserAuthForm>
  );
}
