import { Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../App";
import LoginFacebook from "./facebook/LoginFacebook";
import UserAuthForm from "./form/UserAuthForm";
import LoginGoogle from "./google/LoginGoogle";
import { PasswordReset } from "./password/PasswordReset";

export default function ResetPassword(props) {
  const verify = props.verify;

  const user = useContext(UserContext);

  const Title = () => {
    return (
      <>
        {verify && (
          <Typography component="h1" variant="h5">
            {user.translate("verify")} {user.translate("phone")}
          </Typography>
        )}
        {!verify && (
          <Typography component="h1" variant="h5">
            {user.translate("edit")} {user.translate("password")}
          </Typography>
        )}
      </>
    );
  };

  return (
    <UserAuthForm {...props} title={<Title />}>
      <LoginGoogle />
      <LoginFacebook />
      <PasswordReset {...props} />
    </UserAuthForm>
  );
}
