import React from 'react';
import PropTypes from 'prop-types';
import { Zoom, useScrollTrigger } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
}));

const helper = {
    uid: function () {
        let d = new Date();
        return ((d.getTime() * 1000000) + (d.getMilliseconds() * 1000) + parseInt(Math.random() * 1000)).toString();

    },
    trim: function (str) {
        return str.replace(/\s+/g, '');
    },
    flat: function (arr) {
        return arr.reduce((acc, val) => acc.concat(val), []);
    },
    hash: function (str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            let character = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    },
    addAfter: function (obj, index, newItem) {
        return [
            ...Object.entries(obj).slice(0, index).map(entry => entry[1]),
            newItem,
            ...Object.entries(obj).slice(index).map(entry => entry[1])
        ];
    },
    printdiv: function (printpage, noprint) {
        // let oldstr = document.body.innerHTML;
        if (typeof noprint !== "undefined" && noprint !== "") {
            let hideElems = document.querySelectorAll(`.${noprint}`);

            for (let i in hideElems) {
                if (typeof hideElems[i].style != "undefined") {
                    hideElems[i].style.display = "none"
                };
            }
        } else {

        }
        let headstr = "<html><head><title></title></head><body>";
        let footstr = "</body>";
        // let newstr = document.querySelectorAll(`.${printpage}:not(.${noprint})`)[0].innerHTML;
        let newstr = document.all.item(printpage).innerHTML;
        document.body.innerHTML = headstr + newstr + footstr;
        window.print();
        // document.body.innerHTML = oldstr;
        return true;
    },
    groupBy: function (OurArray, property, sortProperty = "id", sortType = "numbers") {
        return OurArray.reduce(function (accumulator, object) {
            // get the value of our object(age in our case) to use for group    the array as the array key
            const key = object[property] !== "" ? object[property] : "NaN";
            // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
            if (!accumulator[key]) {
                accumulator[key] = [];
            }
            // add the value to the array
            accumulator[key].push(object);
            //sort the array by its key ASC
            accumulator[key].sort(function (a, b) {
                if (sortType === "letters") return a[sortProperty].localeCompare(b[sortProperty]);
                else return a[sortProperty] - b[sortProperty];
            });
            // return the transformed array
            return accumulator;
            // Also we also set the initial value of reduce() to an empty object
        }, {});
    },
    findMissingLetter: function (arr, label) {

        // arr.sort((a, b) => a['val'].localeCompare(b['val']));
        let arrLength = arr.length;
        let newVal = String.fromCharCode(arr[arrLength - 1]['val'].charCodeAt() + 1);

        // we can skip the 1st letter
        for (let i = 1; i < arrLength; i++) {
            // get the char code of the previous letter
            const prev = arr[i - 1]['val'].charCodeAt();
            // get the char code of the current letter
            const current = arr[i]['val'].charCodeAt();

            if (current - prev !== 1) { // if the difference between current and previous is not 1
                // get the character after the previous 
                newVal = String.fromCharCode(prev + 1);
            }
        }

        arr.push({
            val: newVal,
            label: label + newVal
        });

        return { arr, newVal }; // if nothing is found
    },
    findMissingNumber: function (arr, start, label = "Pos ", count = 100) {
        let newVal = start;
        for (let i = 0; i <= arr.length; i++) {
            let tempVal = start === 0 ? i : i + 1;
            tempVal = tempVal.toString();

            if (typeof arr[i] === "undefined" || arr[i]['val'] !== tempVal) {
                newVal = start === 0 ? i : i + 1;
                newVal = newVal.toString();
            }
        }

        arr.push({
            val: newVal,
            label: label + newVal
        })

        return { arr, newVal };
    },
    ScrollTop: function (props) {

        const { children, window } = props;
        const classes = useStyles();
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 100,
        });
    
        const handleClick = (event) => {
            const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    
            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        };
    
        return (
            <Zoom in={trigger}>
                <div onClick={handleClick} role="presentation" className={classes.root}>
                    {children}
                </div>
            </Zoom>
        );
    },
    renderFontIcon: function (icon) {
		icon = icon.replace(/fa_/g, "");

		let solid = "fas"; //far
		if (icon.indexOf("_solid") > -1) solid = "fas";

		icon = icon.replace(/_solid/g, "");
		icon = icon.replace(/_alt/g, "-alt");
		icon = icon.split("_").join("-");

		return [solid, icon];
	},
    searchRestaurants: function (search, restaurants) {

        let name = "", idClass = "", found = null;
        let all = document.querySelectorAll(".MuiGrid-root .MuiGrid-item");

        if(search.length > 0)
        {
            for(var i in all) {
                if(typeof all[i] === "object") {
                    all[i].style.display = "none"
                }
            }

            for(var item in restaurants) {
                name = restaurants[item].name.cirilicToLatin();
                idClass = "rest_"+restaurants[item].id;

                if(name.indexOf(search.cirilicToLatin()) > -1) {
                    found = document.querySelector(".MuiGrid-root .MuiGrid-item."+idClass).style.display = "block";
                }
            }
        } else {
            for(var i in all) {
                if(typeof all[i] === "object") {
                    all[i].style.display = "block"
                }
            }
        }
		
	},
    searchMenu: function (search, _menu) {

        let menu = JSON.parse(JSON.stringify(_menu));

        let idClass = "", found = false, foundDiv = null, firstFound = null, counter = 0;

        let all = document.querySelectorAll("[aria-label='Menu Categories'] .MuiTab-root");

        for(var i in all) {
            if(typeof all[i] === "object") {
                all[i].style.display = "none"
            }
        }

        if(search.length === 0)
        {
            this.resetSearchMenu(all);
            return menu;
        }
        else {

            let newCategories = menu.categories.filter((category, index) => {
                idClass = "tab_"+category.id;

                if(category.items && category.items.length > 0) {
                    let newCategoryItems = category.items.filter((c) => {
                        if(c.name.cirilicToLatin().indexOf(search.cirilicToLatin()) > -1)
                        {
                            found = true;
                            foundDiv = document.querySelector("[aria-label='Menu Categories'] .MuiTab-root."+idClass);
                            if(foundDiv) foundDiv.style.display = "block";
                            if(counter === 0) firstFound = foundDiv;
                            counter++;                          
                            return true;
                        }
                        return false;
                    });
                    // console.log(newCategoryItems)
                    if(newCategoryItems.length > 0)
                    {
                        menu.categories[index].items = [...newCategoryItems];
                        return true;
                    }
                }

                if(category.name.cirilicToLatin().indexOf(search.cirilicToLatin()) > -1)
                {
                    found = true;
                    foundDiv = document.querySelector("[aria-label='Menu Categories'] .MuiTab-root."+idClass);
                    if(foundDiv) foundDiv.style.display = "block";
                    if(counter === 0) firstFound = foundDiv;
                    counter++;
                    return true;
                }

                return false;
            });
            // console.log(newCategories)
            menu.categories = [...newCategories];

            // console.log(menu)

            if(!found) 
            {
                for(var i in all) {
                    if(typeof all[i] === "object") {
                        all[i].style.display = "block";
                    }
                }
                if(all[0])  all[0].click();
                
                setTimeout(()=> {
                    let no_results = document.querySelectorAll(".noResults");
                    for(var i in no_results) {
                        if(typeof no_results[i] === "object") {
                            no_results[i].style.display = "block";
                        }
                    }
                }, 150);
            }else {
                setTimeout(()=> {
                    let no_results = document.querySelectorAll(".noResults");
                    for(var i in no_results) {
                        if(typeof no_results[i] === "object") {
                            no_results[i].style.display = "none";
                        }
                    }
                }, 150);
            }
        }
        // if(firstFound) firstFound.click();

        // menu.found = found;

        return {menu, firstFound};
	},
    resetSearchMenu: function(all) {
        for(var i in all) {
            if(typeof all[i] === "object") {
                all[i].style.display = "block";
            }
        }
        if(all[0]) all[0].click();
        setTimeout(()=> {
            let no_results = document.querySelectorAll(".noResults");
            for(var i in no_results) {
                if(typeof no_results[i] === "object") {
                    no_results[i].style.display = "none";
                }
            }
        }, 150);
    },
    getPhone: function(phone) {
        if(phone.match(/^00/g) !== null) phone = phone.replace(/^00/g, '+');
		else if(phone.match(/^\+/g) === null){
			phone = phone.replace(/^0/g, '');
			phone = "+389" + phone;
		}

		return phone;
    },
}

helper.ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default helper;