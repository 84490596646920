import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function useOnLogin(props) {
  const history = useHistory();

  const location = useLocation();
  const locationHash = location.hash.replace("#", "");

  return useCallback(async () => {
    props.onLogin?.();

    if (props.refreshOnLogin) {
      window.location.reload(false);
    } else if (locationHash?.length) {
      history.push(locationHash);
    } else {
      history.push("/home");
    }
  }, [history, locationHash, props]);
}
