import {
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "reactfire";
import config from "../../config";
import { UserContext } from "../App";
import Show from "../common/Show";
import { PlaceContext } from "../home/Restaurant";
import useWindowDimensions from "../Hooks";

// CSS
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1),
    position: "relative",
    overflowY: "auto",
    borderRadius: theme.spacing(2),
    // background: `url('${process.env.PUBLIC_URL}/images/background.webp')`,
    // backgroundRepeat: 'round',
    // backgroundSize: 'cover',
    maxWidth: 1024,
    "@media screen and (max-width: 768px)": {
      // borderRadius: '0',
    },
  },
  dialogTitle: {
    padding: `0px ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    position: "absolute",
    top: 0,
    right: 0,
    // flexDirection: 'row',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // justifyContent: 'flex-end',
    "& h2": {
      fontSize: 14,
      textTransform: "uppercase",
    },
  },
  productGrid: {
    flexGrow: 1,
    maxWidth: 180,
    minWidth: 180,
    position: "relative",
  },
  descriptionGrid: {
    flexGrow: 1,
    maxWidth: 615,
    minWidth: 223,
    fontSize: 12,
  },
  productTitle: {
    fontSize: 14,
    textTransform: "uppercase",
    margin: "8px 0",
  },
  promo: {
    position: "absolute",
    top: 26,
    right: 26,
    padding: 0,
    color: theme.palette.secondary.main,
  },
  popup: {
    color: "#ff2626",
  },
  popupTitle: {
    color: "#ff2626",
    fontWeight: "bold",
    display: "block",
  },
  productImage: {
    width: "100%",
    borderRadius: "100%",
    display: "block",
    boxShadow: `2px 2px 3px ${theme.palette.secondary.dark}`,
  },
  productImagePromo: {
    width: "100%",
    borderRadius: "50%",
    display: "block",
    boxShadow: `2px 2px 3px red`,
  },
  productFooter: {
    fontSize: 14,
    // paddingTop: theme.spacing(2)
  },
  cartCheckout: {
    flexGrow: 1,
    margin: `0px ${theme.spacing(2)}`,
  },
  hr: {
    border: "none",
    borderTop: "3px double #e3e3e3",
    color: "#e3e3e3",
    overflow: "visible",
    textAlign: "center",
    height: 5,
    width: "100%",
    marginTop: theme.spacing(3),
    borderRadius: "100%",
    "@media screen and (max-width: 650px)": {
      marginTop: theme.spacing(2),
    },
  },
  vl: {
    border: "none",
    borderRight: "3px double #e3e3e3",
    color: "#e3e3e3",
    overflow: "visible",
    textAlign: "center",
    width: 5,
    height: "100%",
    borderRadius: "100%",
  },
  formControl: {
    alignItems: "center",
    justifyContent: "flex-start",
    "& .Mui-disabled  .gridItemImage": {
      opacity: 0.5,
    },
  },
  gridItemLabel: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  gridItemImage: {
    width: "100%",
    height: 100,
    backgroundColor: "#ffffff12",
    borderRadius: theme.spacing(2),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      boxShadow: `2px 2px 3px ${theme.palette.secondary.dark}`,
    },
  },
  optionsGrid: {
    flexGrow: 1,
    maxWidth: 480,
    minWidth: 200,
    display: "flex",
    flexDirection: "column",
    // marginBottom: theme.spacing(1)
  },
  options: {
    "& .MuiTypography-body1": {
      fontSize: 12,
      width: "100%",
    },
    "&.bold .MuiTypography-body1": {
      fontWeight: 700,
    },
  },
  subQty: {
    padding: "6px 12px",
    marginRight: 5,
  },
  quantity: {
    color: "#030303",
    fontWeight: "500",
    lineHeight: "1em",
    width: 40,
    height: 40,
    background: theme.palette.text.primary,
    borderRadius: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addQty: {
    padding: "6px 12px",
    marginLeft: 5,
  },
  comment: {
    marginBottom: theme.spacing(2),
    transition: "width 0.5s ease",
    textOverflow: "ellipsis",
    "& .MuiInputBase-root.MuiInput-root": {
      "&:before": {
        borderBottom: "1px solid #e3e3e3",
      },
      "&:hover": {
        "&:before": {
          borderBottom: "2px solid #e3e3e3",
        },
      },
    },
  },
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  discountPromo: {
    position: "absolute",
    width: 100,
    paddingTop: 18,
    paddingBottom: 2,
    left: -18,
    top: -12,
    zIndex: 9,
    backgroundColor: "red",
    transform: "rotatez(-45deg)",
    transformOrigin: "bottom center",
    fontWeight: "bold",
    fontSize: 19,
    textAlign: "center",
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />;
// });

// React Function Component
export default function ProductDialog(props) {
  const classes = useStyles();
  const { onClose, item, open, cartItem } = props;

  const showAddButtons = props.showAddButtons || false;

  const analytics = useAnalytics();
  const history = useHistory();

  // Get current restaurant from context
  const place = useContext(PlaceContext);
  const user = useContext(UserContext);

  const { height, width } = useWindowDimensions();

  const [quantity, setQuantity] = useState(cartItem ? cartItem.quantity : 1);
  const [price, setPrice] = useState(
    cartItem ? props.calcPrice(cartItem) : item.price
  );
  const [comment, setComment] = useState(cartItem ? cartItem.comment : "");
  const [options, setOptions] = useState(false);
  const [radioOptions, setRadioOptions] = useState(false);
  const [groupOptions, setGroupOptions] = useState({});
  const [widthComment, setWidthComment] = useState(
    width > 683 ? "70%" : "100%"
  );
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableOptions, setDisableOptions] = useState({});

  const piece = user.translate("piece_short");
  const uomOptions = [
    user.translate("liter_short"),
    user.translate("kilogram_short"),
  ];
  const miliUomOptions = [
    user.translate("milliliter_short"),
    user.translate("gram_short"),
  ];

  const formatQuantity = (quantity, uom) => {
    if (uom == 2) {
      return `${quantity} ${piece}`;
    }
    if (quantity < 1000) {
      return `${quantity} ${miliUomOptions[uom]}`;
    }
    return `${user.formatQuantity(quantity / 1000)} ${uomOptions[uom]}`;
  };

  useEffect(() => {
    history.listen((location, action) => {
      // location is an object like window.location
      // console.log(action, location.pathname)

      if (action === "POP") {
        onClose();
      }
    });
  }, []); // Empty array ensures that effect is only run on mount

  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.rootRadio}
        color="primary"
        size={"small"}
        {...props}
      />
    );
  }

  let _options = [],
    _groupOptions = {};
  let priceOptions = [];
  // let count = 0;

  if (item) {
    item.options.map((group) => {
      _groupOptions[group.id] = [];

      if (group.maxQuantity === 1 && group.minQuantity === 1) {
        let find = group.items.find((i) => i.quantity > 0);

        if (find) {
          _options.push({
            id: find.id,
            quantity: find.quantity,
          });
        } else {
          group.items.map((groupItem) => {
            if (groupItem.price > 0) {
              priceOptions.push({
                id: groupItem.id,
                price: groupItem.price,
              });
            }
          });
        }
        // count+=group.items.length;
      } else {
        // if (
        //   group.maxQuantity > 0 &&
        //   group.minQuantity > 0 &&
        //   !options &&
        //   !cartItem
        // ) {
        //   setDisableAdd(true);
        // }
        group.items.map((groupItem) => {
          if (cartItem) {
            let option = cartItem.options.find((i) => i.id === groupItem.id);
            if (option) {
              _options.push(option);
              _groupOptions[group.id].push(option);
            }

            if (groupItem.quantity === 0 && groupItem.price > 0) {
              priceOptions.push({
                id: groupItem.id,
                price: groupItem.price,
              });
            }
          } else if (groupItem.quantity > 0) {
            _groupOptions[group.id].push({
              id: groupItem.id,
              quantity: groupItem.quantity,
            });
            _options.push({
              id: groupItem.id,
              quantity: groupItem.quantity,
            });
          } else if (groupItem.price > 0) {
            priceOptions.push({
              id: groupItem.id,
              price: groupItem.price,
            });
          }
        });
        // count+=group.items.length;
      }

      if (Object.keys(_groupOptions[group.id]).length === 0) {
        delete _groupOptions[group.id];
      }
    });
  }

  const checkDisabled = useCallback(
    (_groupOptions) => {
      let _disableAdd = false;
      let _disableOptions = { ...disableOptions };

      for (let key in item.options) {
        let _group = item.options[key];

        if (
          _groupOptions[_group.id] &&
          _groupOptions[_group.id].length === _group.maxQuantity
        ) {
          _disableOptions[_group.id] = [];
          var filtered = _group.items.filter(function (option) {
            return !this.find((i) => i.id === option.id);
          }, _groupOptions[_group.id]);
          filtered.forEach(function (filter) {
            _disableOptions[_group.id].push(filter.id);
          });
        } else {
          if (
            _disableOptions[_group.id] &&
            _disableOptions[_group.id].length > 0
          ) {
            delete _disableOptions[_group.id];
          }
        }

        if (_group.maxQuantity > 0) {
          if (
            _groupOptions[_group.id] &&
            _groupOptions[_group.id].length < _group.minQuantity
          ) {
            _disableAdd = true;
          }
        }
      }
      setDisableOptions({ ..._disableOptions });
      setDisableAdd(_disableAdd);
    },
    [disableOptions, item.options]
  );

  useEffect(() => {
    if (
      !options &&
      Object.keys(_groupOptions).length > 0 &&
      Object.keys(disableOptions).length === 0
    ) {
      setGroupOptions({ ..._groupOptions });
      checkDisabled(_groupOptions);
    }
  }, [_groupOptions, checkDisabled, disableOptions, options]);

  if (!options && item) {
    setOptions([..._options]);
  }

  const onEnterComment = (e) => {
    e.stopPropagation();
    setComment(e.target.value);
  };

  const hasDiscount = item.discount > 0 && item.discount < 1;

  const minPrice = Math.round(
    hasDiscount ? item.minPrice * (1 - item.discount) : item.minPrice
  );
  const maxPrice = Math.round(
    hasDiscount ? item.maxPrice * (1 - item.discount) : item.maxPrice
  );

  const basePrice =
    item.minPrice == item.maxPrice
      ? item.minPrice.toString()
      : `${item.minPrice} - ${item.maxPrice}`;

  const hasPromo = item.offer === "PROMO";
  const hasPopup = item.offer === "POPUP";

  const calcPriceProduct = (options) => {
    let _price = item.price;
    for (let key in priceOptions) {
      if (options.find((i) => i.id === priceOptions[key].id)) {
        _price += priceOptions[key].price;
      }
    }

    setPrice(Math.round(hasDiscount ? _price * (1 - item.discount) : _price));
  };

  useEffect(() => {
    if (Boolean(props.item)) {
      calcPriceProduct(options);
      analytics.logEvent("view_item", {
        id: props.item.id,
        name: props.item.name,
      });
    }
  }, [props.item]);

  const onChangeOptions = (option, group, e) => {
    let _groupOptions = { ...groupOptions };

    if (group.maxQuantity > 0) {
      if (!e.target.checked) {
        _groupOptions[group.id] = _groupOptions[group.id].filter(
          (i) => i.id !== option.id
        );
      } else if (e.target.checked) {
        if (!_groupOptions[group.id]) _groupOptions[group.id] = [];
        _groupOptions[group.id].push({
          id: option.id,
          quantity: 1,
        });
      }
    }

    let _options = Object.assign(options);
    if (!e.target.checked) {
      _options = _options.filter((i) => i.id !== option.id);
    } else if (e.target.checked) {
      _options.push({
        id: option.id,
        quantity: 1,
      });
    }

    setOptions([..._options]);
    setGroupOptions({ ..._groupOptions });

    calcPriceProduct(_options);
    checkDisabled(_groupOptions);

    e.stopPropagation();
  };

  const onChangeRadio = (e) => {
    let _radioOptions = { ...radioOptions };
    let oldOption;
    if (_radioOptions[e.target.name]) oldOption = _radioOptions[e.target.name];

    _radioOptions[e.target.name] = {
      id: e.target.value,
      quantity: 1,
    };

    let _options = Object.assign(options);
    if (oldOption && oldOption.id) {
      _options = _options.filter((i) => i.id !== oldOption.id);
    }
    _options.push(_radioOptions[e.target.name]);

    setOptions([..._options]);
    setRadioOptions({ ..._radioOptions });

    calcPriceProduct(_options);

    e.stopPropagation();
  };

  const checkDisabledOptions = (groupItem, group) => {
    const foundDisabled =
      disableOptions &&
      disableOptions[group.id] &&
      typeof disableOptions[group.id].find((i) => i === groupItem.id) !==
        "undefined";
    const condition =
      !showAddButtons || groupItem.quantity > 0 || foundDisabled;
    // if (condition) setDisableOptions({})
    return condition;
  };

  const onAddItem = (e) => {
    place.addItem(item, item.id, quantity, comment, options);
    // place.addDefaultItem(item);
    if (props.redirect) history.push(props.redirect);
    onClose();
    e.stopPropagation();
  };
  const onEditItem = (e) => {
    place.updateItem(cartItem.id, quantity, comment, options);
    if (props.redirect) history.push(props.redirect);
    onClose();
    e.stopPropagation();
  };

  // const [updateCart, { loading: cartUpdateLoading, data: updateCartResult}] = useMutation(gql`
  // mutation($posId: String!) {
  //     userMenu_makeOrder(
  //       posId: $posId
  //       finish: false
  //       order: {
  //         comment: ""
  //         orderType: "IN_RESTAURANT"
  //         items: ${JSON.stringify(place.cart)}
  //       }
  //     ) {
  //       error
  //       message
  //     }
  //   }
  //   `);

  if (item === false) {
    return <div />;
  }

  let img, webp, png, jpg;

  img = item.image;
  webp = item.image.replace(".jpg", ".webp").replace(".png", ".webp");
  png = item.image.replace(".webp", ".png").replace(".jpg", ".png");
  jpg = item.image.replace(".webp", ".jpg").replace(".png", ".jpg");

  if (item.image.indexOf("http") === -1) {
    img = `${config.apiImagesUrl}/${img}`;
    webp = `${config.apiImagesUrl}/${webp}`;
    png = `${config.apiImagesUrl}/${png}`;
    jpg = `${config.apiImagesUrl}/${jpg}`;
  }

  return (
    <Dialog
      onClose={onClose}
      classes={{ paper: classes.paper }}
      maxWidth={"lg"}
      fullWidth
      // fullScreen={width < 768 ? true : false}
      open={open}
      scroll="body"
    >
      <div className="gradient">
        <Show if={hasDiscount}>
          <div className={classes.discountPromo}>
            {Math.round(item.discount * 100)}%
          </div>
        </Show>
        <div className={classes.dialogTitle}>
          {/* <DialogTitle>{props.item.name}</DialogTitle> */}
          <IconButton
            edge="end"
            color="secondary"
            onClick={onClose}
            aria-label="close"
            style={{ padding: 5, zIndex: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent style={{ padding: "8px 16px" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent={item.image !== "" ? "center" : "flex-start"}
            alignItems="flex-start"
          >
            {item.image !== "" && (
              <Grid item xs={4} className={classes.productGrid}>
                <picture>
                  <source srcSet={webp} type="image/webp" />
                  <source srcSet={png} type="image/png" />
                  <source srcSet={jpg} type="image/jpeg" />
                  <img
                    className={
                      hasPopup || hasPromo
                        ? classes.productImagePromo
                        : classes.productImage
                    }
                    src={img}
                    alt={item.name}
                  />
                </picture>

                {(hasPromo || hasPopup) && (
                  <IconButton
                    className={`${classes.promo} ${classes.popup}`}
                    edge="end"
                  >
                    <StarBorderIcon />
                  </IconButton>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={item.image !== "" ? 8 : 12}
              className={classes.descriptionGrid}
            >
              <p className={classes.productTitle}>
                {props.item.name}{" "}
                {(hasPopup || hasPromo) !== false && (
                  <span className={classes.popupTitle}>
                    <br />
                    {user.translate("promotion")}
                  </span>
                )}
              </p>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </Grid>
          </Grid>

          <hr className={classes.hr} />

          {item.options.length > 0 && (
            <>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                className={classes.productFooter}
              >
                {item.options.map((group, index) => {
                  if (group.maxQuantity === 1 && group.minQuantity === 1) {
                    let find = group.items.find((i) => i.quantity > 0);
                    let defaultValue = find ? find.id : undefined;

                    return (
                      <RadioGroup
                        key={"group_" + index}
                        row
                        defaultValue={defaultValue}
                        onChange={onChangeRadio}
                        name={group.id}
                        className={classes.formControl}
                      >
                        {group.items.map((groupItem, index) => {
                          let img = null,
                            webp,
                            png,
                            jpg,
                            bkg = null;
                          if (groupItem.image && groupItem.image !== "") {
                            img = groupItem.image;
                            webp = groupItem.image
                              .replace(".jpg", ".webp")
                              .replace(".png", ".webp");
                            png = groupItem.image
                              .replace(".webp", ".png")
                              .replace(".jpg", ".png");
                            jpg = groupItem.image
                              .replace(".webp", ".jpg")
                              .replace(".png", ".jpg");

                            if (groupItem.image.indexOf("http") === -1) {
                              img = `${config.apiImagesUrl}/${img}`;
                              webp = `${config.apiImagesUrl}/${webp}`;
                              png = `${config.apiImagesUrl}/${png}`;
                              jpg = `${config.apiImagesUrl}/${jpg}`;
                            }

                            bkg = {
                              backgroundImage: `-webkit-image-set( url('${webp}') 1x, url('${png}') 1x, url('${jpg}') 1x, url('${img}') 1x)`,
                            };
                          }
                          const title = (
                            groupItem.name +
                            (groupItem.price === 0
                              ? ""
                              : ` ${groupItem.price}  ${user.translate("den")}`)
                          ).capitalizeAll();
                          // const ingredients = groupItem.items.joinOf(", ", (ingredient) => `${ingredient.name} (${formatQuantity(ingredient.quantity, ingredient.uom)})`)
                          const ingredients = groupItem.items
                            .orderByDesc((it) =>
                              it.uom == 2 ? it.quantity * 10000 : it.quantity
                            )
                            .joinOf(", ", (ingredient) =>
                              ingredient.name.toLowerCase()
                            );
                          return (
                            <Grid
                              item
                              xs={3}
                              className={classes.optionsGrid}
                              key={"group_item_" + index}
                            >
                              {index === 0 &&
                                group.name &&
                                group.name !== "" && (
                                  <Typography
                                    variant="subtitle"
                                    style={{
                                      marginRight: 15,
                                      marginTop: 8,
                                      marginBottom: 4,
                                    }}
                                  >
                                    {group.name}:
                                  </Typography>
                                )}
                              <FormControlLabel
                                key={"group_item_" + index}
                                value={groupItem.id}
                                // disabled={defaultValue ? true : undefined}
                                className={`${classes.options} ${
                                  groupItem.mainProduct ? "bold" : ""
                                }`}
                                style={bkg && { marginTop: 8, marginBottom: 8 }}
                                control={<StyledRadio />}
                                label={
                                  <div className={classes.gridItemLabel}>
                                    {bkg && (
                                      <div
                                        className={`${classes.gridItemImage} gridItemImage`}
                                        style={bkg}
                                      >
                                        &nbsp;
                                      </div>
                                    )}
                                    <span
                                      style={{
                                        whiteSpace: "nowrap",
                                        fontSize: 13,
                                        color: "#CCC",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {title}
                                    </span>
                                    <br />
                                    <div
                                      style={{
                                        fontSize: 10,
                                        color: "#888",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {ingredients}
                                    </div>
                                  </div>
                                }
                              />
                            </Grid>
                          );
                        })}
                      </RadioGroup>
                    );
                  } else {
                    return (
                      <FormGroup
                        row
                        key={"group_" + index}
                        className={classes.formControl}
                      >
                        {group.items.map((groupItem, index) => {
                          let img = null,
                            webp,
                            png,
                            jpg,
                            bkg = null;
                          if (groupItem.image && groupItem.image !== "") {
                            img = groupItem.image;
                            webp = groupItem.image
                              .replace(".jpg", ".webp")
                              .replace(".png", ".webp");
                            png = groupItem.image
                              .replace(".webp", ".png")
                              .replace(".jpg", ".png");
                            jpg = groupItem.image
                              .replace(".webp", ".jpg")
                              .replace(".png", ".jpg");

                            if (groupItem.image.indexOf("http") === -1) {
                              img = `${config.apiImagesUrl}/${img}`;
                              webp = `${config.apiImagesUrl}/${webp}`;
                              png = `${config.apiImagesUrl}/${png}`;
                              jpg = `${config.apiImagesUrl}/${jpg}`;
                            }

                            bkg = {
                              backgroundImage: `-webkit-image-set( url('${webp}') 1x, url('${png}') 1x, url('${jpg}') 1x, url('${img}') 1x)`,
                            };
                          }
                          const ingredients = groupItem.items
                            .orderByDesc((it) =>
                              it.uom == 2 ? it.quantity * 10000 : it.quantity
                            )
                            .joinOf(", ", (ingredient) =>
                              ingredient.name.toLowerCase()
                            );
                          let label = (
                            <div className={classes.gridItemLabel}>
                              {bkg && (
                                <div
                                  className={`${classes.gridItemImage} gridItemImage`}
                                  style={bkg}
                                >
                                  &nbsp;
                                </div>
                              )}
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: 13,
                                  lineHeight: "normal",
                                }}
                              >
                                {groupItem.name.capitalizeAll()}
                                {groupItem.price === 0
                                  ? ""
                                  : ` ${groupItem.price}  ${user.translate(
                                      "den"
                                    )}`}
                                {groupItem.quantity > 1 ? (
                                  <span
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >{` x ${groupItem.quantity}`}</span>
                                ) : (
                                  ""
                                )}
                              </span>
                              <br />
                              <div
                                style={{
                                  fontSize: 10,
                                  color: "#888",
                                  lineHeight: 1.1,
                                }}
                              >
                                {ingredients}
                              </div>
                            </div>
                          );

                          return (
                            <Grid
                              item
                              xs={3}
                              key={"group_item_" + index}
                              className={classes.optionsGrid}
                            >
                              {index === 0 &&
                                group.name &&
                                group.name !== "" && (
                                  <Typography
                                    variant="subtitle"
                                    style={{
                                      marginRight: 15,
                                      marginTop: 8,
                                      marginBottom: 4,
                                    }}
                                  >
                                    {group.name}:
                                  </Typography>
                                )}
                              <FormControlLabel
                                className={`${classes.options} ${
                                  groupItem.mainProduct ? "bold" : ""
                                }`}
                                style={bkg && { marginTop: 8, marginBottom: 8 }}
                                control={
                                  <Checkbox
                                    name={groupItem.id}
                                    color="primary"
                                    size={"small"}
                                    defaultChecked={groupItem.quantity > 0}
                                    disabled={checkDisabledOptions(
                                      groupItem,
                                      group
                                    )}
                                    // checked={options && options.find((i) => i.id === groupItem.id) !== null}
                                    value={groupItem.price}
                                    onClick={(e) =>
                                      onChangeOptions(groupItem, group, e)
                                    }
                                  />
                                }
                                label={label}
                              />
                            </Grid>
                          );
                        })}
                      </FormGroup>
                    );
                  }
                })}
              </Grid>
            </>
          )}

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent={
              width > 683
                ? showAddButtons !== false
                  ? "space-between"
                  : "center"
                : "center"
            }
            style={{ marginTop: 32 }}
          >
            {showAddButtons !== false && (
              <Grid item xs={width > 683 ? 9 : 12} style={{ marginRight: 8 }}>
                <ClickAwayListener
                  onClickAway={(e) =>
                    setWidthComment(width > 683 ? "70%" : "100%")
                  }
                >
                  <TextField
                    multiline
                    maxRows={5}
                    value={comment}
                    placeholder={user.translate("comment")}
                    className={classes.comment}
                    onFocus={(e) => setWidthComment("100%")}
                    style={{ width: width > 683 ? widthComment : "100%" }}
                    onChange={onEnterComment}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EditIcon color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          style={{
                            display: comment !== "" ? "flex" : "none",
                            cursor: "pointer",
                          }}
                          onClick={(e) => setComment("")}
                          position="end"
                        >
                          <CloseIcon color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </ClickAwayListener>
              </Grid>
            )}

            <Grid item style={{ textAlign: "center" }}>
              {hasDiscount !== false && (
                <div
                  style={{
                    margin: `8px 0`,
                    textDecoration: "line-through",
                    textAlign: "center",
                  }}
                >
                  {user.translate("price")}: {basePrice} {user.translate("den")}
                </div>
              )}
              {showAddButtons !== false && (
                <IconButton
                  disabled={quantity < 2}
                  className={classes.subQty}
                  color="secondary"
                  onClick={(e) => setQuantity(quantity - 1)}
                >
                  &#8722;
                </IconButton>
              )}
              <span className={classes.quantity}>{quantity}</span>
              {showAddButtons !== false && (
                <IconButton
                  color="secondary"
                  className={classes.addQty}
                  onClick={(e) => setQuantity(quantity + 1)}
                >
                  &#43;
                </IconButton>
              )}
              <div style={{ marginTop: 8 }}>
                {user.translate("price")}: {price} {user.translate("den")}. x{" "}
                {quantity}
              </div>
              <div style={{ marginBottom: 8 }}>
                {user.translate("total")}: {price * quantity}{" "}
                {user.translate("den")}.
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {showAddButtons !== false && (
            <Button
              variant="outlined"
              color="primary"
              disabled={disableAdd}
              className={classes.cartCheckout}
              onClick={(e) => (cartItem ? onEditItem(e) : onAddItem(e))}
            >
              {cartItem
                ? user.translate("edit_card")
                : user.translate("add_to_card")}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}

ProductDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.any.isRequired,
};
