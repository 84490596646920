import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

// function Alert(props) {
//     return <Alert elevation={6} variant="filled" {...props} />;
// }

export default function SnackBarAdd(props) {

    const handleClose = () => {
        props.setOpenSnackBar(false);
    };

    return (
        <Snackbar 
            open={props.openSnackBar} 
            autoHideDuration={5000} 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}>
            <Alert elevation={6} variant="filled"  onClose={handleClose} severity={props.severity ? props.severity : "success"}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}
