import { gql, useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LanguageIcon from "@mui/icons-material/Language";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import React, {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { useAnalytics } from "reactfire";
import helper from "../../common/Helper";
import SnackBarAdd from "../../common/SnackBarAdd";
import { UserContext } from "../App";
import Checkout from "../menu/Checkout";
import Orders from "../menu/Orders";
import AppNavigation from "./AppNavigation";

var hash = require("object-hash");

const Menu = React.lazy(() => import("../menu/Menu"));

const GET_MENU = gql`
  query ($posId: String!, $lang: String) {
    userMenu_menu(posId: $posId, lang: $lang) {
      error
      lang
      menu {
        canOrder
        isOnTable
        isMember
        pos {
          name
          memberOrders
          webMenu
          webOrders
          tableOrders
        }
        categories {
          id
          name
          icon
          items {
            id
            name
            search
            price
            minPrice
            maxPrice
            categoryId
            description
            discount
            popupImage
            image
            offer
            options {
              id
              name
              minQuantity
              maxQuantity
              displayIndex
              items {
                id
                groupId
                name
                image
                price
                mainProduct
                quantity
                items {
                  quantity
                  name
                  uom
                }
              }
            }
          }
        }
      }
    }
  }
`;

const QUERY_CART = gql`
  query ($posId: String!, $lang: String!) {
    userMenu_cart(posId: $posId, lang: $lang) {
      comment
      orderType
      time
      items {
        id
        comment
        menuItemId
        quantity
        options {
          id
          quantity
        }
      }
    }
  }
`;

export const PlaceContext = createContext({
  cart: [],
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // background: '#ededed',
    // backgroundColor: theme.palette.background.paper,
  },
  content: {
    height: "100%",
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  progress: {
    position: "fixed",
    top: "50%",
  },
}));

const Restaurant = (props) => {
  const classes = useStyles();

  const { placeId, drawerMenu } = useParams();

  const analytics = useAnalytics();
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);

  const [cart, setCart] = useState({
    items: [],
    isDirty: false,
    isLoading: true,
  });
  const [isCartUpating, setCartUpdating] = useState(false);

  const [callType, setCallType] = useState({
    callIt: false,
    type: false,
    message: false,
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [search, setSearch] = useState("");

  const {
    data: apiMenu,
    loading: loadingMenu,
    refetchMenu,
  } = useQuery(GET_MENU, {
    errorPolicy: "ignore",
    pollInterval: 300000,
    skip: user.lang == null,
    variables: {
      posId: placeId,
      lang: user.lang,
    },
  });

  const menu =
    loadingMenu == false && apiMenu
      ? apiMenu.userMenu_menu.menu
      : { isLoading: true, categories: [] };
  const canOrder = menu ? (menu.canOrder ? menu.canOrder : false) : false;

  let filteredMenu = useMemo(() => {
    if (search.length == 0 || menu.categories.length == 0) return menu;
    let searchLatin = search.cirilicToLatin();

    let filteredCategories = menu.categories
      .map((category, index) => {
        let filteredItems = category.items.filter(
          (c) => c.search.cirilicToLatin().indexOf(searchLatin) > -1
        );

        if (
          filteredItems.length == 0 &&
          category.items.length > 0 &&
          category.name.cirilicToLatin().indexOf(searchLatin) > -1
        ) {
          return { ...category, categoryMatched: true, searchItems: [] };
        }

        return { ...category, searchItems: filteredItems, search: search };
      })
      .filter(
        (category, index) =>
          category.categoryMatched || category.searchItems.length > 0
      )
      .sort((a, b) => b.searchItems.length - a.searchItems.length);

    return { ...menu, categories: filteredCategories };
  }, [hash(menu), search]);

  const cartItems = (cart.items || []).map((cartItem) => ({
    ...cartItem,
    source: undefined,
    isSending: undefined,
  }));

  const [
    updateCart,
    {
      loading: cartUpdateLoading,
      data: updateCartResult,
      error: updateCartError,
    },
  ] = useMutation(gql`
        mutation($posId: String!) {
            userMenu_makeOrder(
				posId: $posId
				finish: false
				order: {
					comment: ""
					orderType: "IN_RESTAURANT"
					items: ${JSON.stringify(cartItems).replace(/"([^"]+)":/g, "$1:")}
				}) {
				error
				message
				cart {
					comment
					orderType
					time
					items {
						id
						comment
						menuItemId
						quantity
						options {
							id
							quantity
						}
					}
				}
            }
        }
    `);

  const {
    data: apiCart,
    loading: loadingCart,
    refetch: refetchCart,
  } = useQuery(QUERY_CART, {
    errorPolicy: "ignore",
    pollInterval: 300000,
    skip:
      !canOrder ||
      user.lang == null ||
      (drawerMenu != "menu" &&
        drawerMenu != "checkout" &&
        drawerMenu != "active-orders"),
    variables: {
      posId: placeId,
      lang: user.lang,
    },
  });

  useEffect((e) => {
    if (cart.isDirty && !isCartUpating) {
      console.log("Cart need update");
      updateCart({
        variables: {
          posId: placeId.toString(),
        },
      });
      setCartUpdating(true);
    }
  });

  useEffect(() => {
    if (search.length > 0) {
      analytics.logEvent("search", { search_term: search, lang: user.lang });
    }
  }, [search]);

  const CALL_TYPE = gql`
    mutation ($posId: String!, $type: String!) {
      userMenu_call(posId: $posId, type: $type) {
        error
        message
      }
    }
  `;

  const [
    userMenu_call,
    { loading: callLoading, data: callData, error: callError },
  ] = useMutation(CALL_TYPE, {
    variables: { posId: placeId, type: callType.type },
    skip: !callType.callIt,
  });

  useEffect(() => {
    if (callType.callIt && callType.type && !callLoading) {
      userMenu_call();
      setCallType({ ...callType, callIt: false });
    }
  });

  useEffect(() => {
    if (!callType.callIt && callType.type && !callLoading && callData) {
      if (callData && callData.userMenu_call.error != null) {
        setCallType({
          ...callType,
          message: callData.userMenu_call.error,
          type: false,
        });
      } else {
        setCallType({
          ...callType,
          message: user.translate(`${callType.type}_arrive_shortly`),
          type: false,
        });
      }
      setOpenSnackBar(true);
    }
  });

  const findMenuItem = (id) => {
    for (const i in menu.categories) {
      const category = menu.categories[i];
      if (typeof category === "object") {
        const item = category.items.find((item) => item.id == id);
        if (item != undefined) return item;
      }
    }
  };

  const copyCartItems = (items) =>
    items.map((x) => ({
      ...{
        ...x,
        options: x.options.map((o) => ({ ...o, __typename: undefined })),
      },
      source: findMenuItem(x.menuItemId) || {},
      __typename: undefined,
    }));

  useEffect(() => {
    if (
      !cartUpdateLoading &&
      isCartUpating &&
      cart.isDirty &&
      (updateCartResult || updateCartError)
    ) {
      console.log("Cart updated");
      if (updateCartResult != undefined) {
        let result = updateCartResult && updateCartResult.userMenu_makeOrder;
        if (result.error) {
          setCart({
            items: cart.items.filter((x) => x.isSending != true),
            isDirty: false,
            time: cart.time,
          });
          alert(result.error);
          console.log("tuka");
        } else {
          setCart({
            items: copyCartItems(result.cart.items),
            isDirty: false,
            time: result.cart.time,
          });
        }
      } else if (updateCartError != undefined) {
        alert(`${user.translate("no_internet")}!`);

        setCart({
          items: cart.items.filter((x) => x.isSending != true),
          isDirty: false,
          time: cart.time,
        });
      }
      setCartUpdating(false);
    }
  });

  let userCart = apiCart && apiCart.userMenu_cart && apiCart.userMenu_cart;

  useEffect(() => {
    console.log("loadingCart", loadingCart);
    if (
      loadingCart === false &&
      loadingMenu === false &&
      userCart !== undefined &&
      (cart.time === undefined || userCart.time > cart.time)
    ) {
      let cartItems = copyCartItems(userCart.items);
      if (JSON.stringify(cartItems) !== JSON.stringify(cart.items)) {
        console.log("Update cart items");
        setCart({
          items: copyCartItems(userCart.items),
          isDirty: false,
          time: userCart.time,
          isLoading: false,
        });
      }
    } else if (userCart && userCart.time == 0 && cart && cart.time > 0) {
      console.log("Reset cart");
      setCart({
        items: [],
        isDirty: true,
        isLoading: loadingCart,
        time: cart.time + 1,
      });
    } else if (
      loadingCart === false &&
      loadingMenu === false &&
      cart.isLoading
    ) {
      setCart((currentCart) => ({ ...currentCart, isLoading: false }));
    }
  }, [cart, copyCartItems, loadingCart, loadingMenu, userCart]);

  const addItem = useCallback(
    (source, menuItemId, quantity, comment, options) => {
      const newCartItems = [...cart.items];
      newCartItems.push({
        id: helper.uid(),
        menuItemId,
        quantity,
        comment,
        options: options || [],
        source,
        isSending: true,
      });
      const newCart = {
        items: newCartItems,
        isDirty: true,
        time: cart.time + 1,
      };
      setCart(newCart);
      return newCart;
    },
    [cart.items, cart.time]
  );

  const removeItem = (cartItemId) => {
    const newCartItems = [...cart.items];

    newCartItems.removeIf((item) => item.id == cartItemId);

    const newCart = { items: newCartItems, isDirty: true, time: cart.time + 1 };
    setCart(newCart);
    return newCart;
  };

  const updateItem = (cartItemId, quantity, comment, options) => {
    if (quantity == 0) {
      return removeItem(cartItemId);
    }
    const newCartItems = [...cart.items];
    let cartItem = newCartItems.find((item) => item.id == cartItemId);
    if (cartItem == undefined) {
      console.error(`Cart item ${cartItemId} doesn't exist`);
      return;
    }
    if (quantity != undefined) cartItem.quantity = quantity;
    if (comment != undefined) cartItem.comment = comment;
    if (options != undefined) cartItem.options = options;

    cartItem.isSending = true;

    const newCart = { items: newCartItems, isDirty: true, time: cart.time + 1 };
    setCart(newCart);
    return newCart;
  };

  const openCheckout = () => {
    history.push(`/place/${placeId}/checkout`);

    analytics.logEvent("begin_checkout", {
      placeId: placeId,
      placeName: place?.name || "",
    });
  };

  const finishOrder = () => {
    console.log("finishorder");
    setCart({ items: [], isDirty: false });
  };

  const addDefaultItem = useCallback(
    (item, quantity) => {
      const defaultOptions = item.options.flatMap(
        ({ minQuantity, maxQuantity, items }) => {
          let result = [];
          let index = 0;
          let usedQuantity = 0;
          while (minQuantity > usedQuantity && index < items.length) {
            const item = items[index++];

            if (item.quantity) {
              result.push({ id: item.id, quantity: item.quantity });
              usedQuantity += item.quantity;
            }
          }

          return result;
        }
      );

      return addItem(item, item.id, quantity || 1, "", defaultOptions);
    },
    [addItem]
  );

  const place =
    props?.restaurants
      ?.flatMap((restaurant) => restaurant.places)
      .find((p) => p.id == placeId) || {};
  let item = props.restaurants?.find((restaurant) =>
    restaurant.places?.find((p) => p.id == placeId)
  );

  useEffect(() => {
    if (item === undefined) return;
    analytics.logEvent("restaurant", item, {
      placeId: placeId,
      placeName: place?.name || "",
    });
  }, [placeId, item?.name, item, analytics, place?.name]);

  if (props.restaurants == undefined) {
    return (
      <div className={classes.root}>
        <AppNavigation title={"..."} home="/home" search transparent>
          <div className={classes.content}>
            <CircularProgress />
          </div>
        </AppNavigation>
      </div>
    );
  }

  const placeContext = {
    isLoading:
      loadingMenu || apiMenu == undefined || apiMenu.userMenu_menu == undefined,
    place: place,
    cart: cart || { items: [] },
    cartItems,
    addItem,
    addDefaultItem,
    updateItem,
    removeItem,
    openCheckout,
    finishOrder,
  };

  const showAddButtons = canOrder || (place && place.tableOrders);

  const menus = [
    [
      // { key: 'home', exact: true, text: user.translate("restaurants"), icon: <RestaurantMenuIcon /> },
      { key: "menu", text: user.translate("menu"), icon: <MenuBookIcon /> },
      // cart && cart.items.length > 0 ? { key: 'checkout', text: user.translate("make_order"), icon: <HomeIcon /> } : {},
      canOrder
        ? {
            key: "active-orders",
            text: user.translate("active_orders"),
            icon: <AddShoppingCartIcon />,
          }
        : {},
      canOrder
        ? {
            key: "orders",
            text: user.translate("all_orders"),
            icon: <LocalMallIcon />,
          }
        : {},
      { key: "divider" },
      {
        key: `language#${location.pathname}`,
        exact: true,
        text: user.translate("select_language"),
        icon: <LanguageIcon />,
      },
      { key: "divider" },
      user.me.isLoggedIn
        ? { key: "profile", popup: true, text: user.translate("profile") }
        : {},
      user.me.isLoggedIn
        ? {
            key: "password",
            popup: true,
            text: user.translate("change_password"),
            icon: <VpnKeyIcon />,
          }
        : {},
      user.me.isLoggedIn
        ? {
            key: "logout",
            popup: true,
            text: user.translate("logout"),
            icon: <ExitToAppIcon />,
          }
        : {
            key: `login#${location.pathname}`,
            exact: true,
            text: user.translate("login"),
            icon: <LockOpenIcon />,
          },
    ],
  ];

  if (filteredMenu === null) {
    return "Menu Not Ready";
  }

  if (place || loadingMenu || user.lang == null) {
    const first =
      filteredMenu && filteredMenu.categories ? filteredMenu.categories[0] : {};
    return (
      <PlaceContext.Provider value={placeContext}>
        <div className={classes.root}>
          <AppNavigation
            key="drawer"
            item={(place && place.name) || "..."}
            place={(place && place.name) || "..."}
            setCallType={setCallType}
            callType={callType}
            transparent
            drawer={menus}
            drawerMenu={drawerMenu}
            restaurant={item ? item.id : false}
            canOrder={canOrder}
            refetch={props.refetch}
            onSearch={setSearch}
          >
            <div className={classes.content}>
              <Switch>
                <Route key="menu" exact path="/place/:placeId/menu">
                  <Suspense
                    fallback={
                      <div className="App AppLoading">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Menu
                      menu={filteredMenu}
                      restaurant={item}
                      search={search}
                      first={first}
                      canOrder={canOrder}
                      showAddButtons={showAddButtons}
                    />
                  </Suspense>
                </Route>
                <Route key="productId" path="/place/:placeId/menu/:productId/">
                  <Suspense
                    fallback={
                      <div className="App AppLoading">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Menu
                      menu={filteredMenu}
                      restaurant={item}
                      search={search}
                      first={first}
                      canOrder={canOrder}
                      showAddButtons={showAddButtons}
                    />
                  </Suspense>
                </Route>
                <Route key="checkout" exact path="/place/:placeId/checkout">
                  <Suspense
                    fallback={
                      <div className="App AppLoading">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Checkout cart={cart || {}} />
                  </Suspense>
                </Route>
                <Route
                  key="active-orders"
                  exact
                  path="/place/:placeId/active-orders"
                >
                  <Suspense
                    fallback={
                      <div className="App AppLoading">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Orders
                      cart={cart || {}}
                      findMenuItem={findMenuItem}
                      type={drawerMenu}
                    />
                  </Suspense>
                </Route>
                <Route key="orders" exact path="/place/:placeId/orders">
                  <Suspense
                    fallback={
                      <div className="App AppLoading">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Orders
                      cart={cart || {}}
                      findMenuItem={findMenuItem}
                      type={drawerMenu}
                    />
                  </Suspense>
                </Route>
                <Route key="placeId" exact path="/place/:placeId">
                  <Redirect to={{ pathname: `/place/${placeId}/menu` }} />
                </Route>
                <Route key="404">
                  <p>Page Not Found</p>
                </Route>
              </Switch>
            </div>
          </AppNavigation>
        </div>

        <SnackBarAdd
          message={callType.message}
          severity={
            !callLoading && callData
              ? callData.userMenu_call.error != null
                ? "warning"
                : "success"
              : "success"
          }
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      </PlaceContext.Provider>
    );
  }

  return <Redirect to={{ pathname: "/home" }} />;
};

export default Restaurant;
