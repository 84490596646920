import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../App";
import LoadingBox from "../common/LoadingBox";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import { useRedirectLocationFromUrl } from "../common/useRedirectLocationFromUrl";

export const PasswordReset = ({ verify, isLoggedIn, ...props }) => {
  const classes = useStyles();

  const user = useContext(UserContext);

  const history = useHistory();

  const redirectUri = useRedirectLocationFromUrl();

  const { verifier } = useParams();

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState("");

  const RESET_PASS = gql`
    mutation ($code: String!, $password: String!) {
      auth_resetPassword(codeWithVerifier: $code, password: $password) {
        message
        ok
      }
    }
  `;

  const [resetPass, { loading }] = useMutation(RESET_PASS);

  const checkCode = () => {
    if (loading) return true;
    let regCode = /^\+?[0-9]+$/g;

    if (!code.length || !regCode.test(String(code))) return true;

    return !password.length;
  };

  const sms = async (e) => {
    e.preventDefault();
    props.onStartLoading();

    try {
      const { data, errors } = await resetPass({
        variables: {
          code: code + "_" + verifier,
          password: password,
        },
      });

      if (data) {
        if (data.auth_resetPassword.ok) {
          setSuccess(data.auth_resetPassword.message);
        } else {
          props.onError(data.auth_resetPassword.message);
        }
      } else if (errors != null) {
        props.onError(user.translate("register_error"));
      }
    } catch (e) {}
  };

  const goHome = () => {
    if (isLoggedIn) {
      props.onLogin();
      if (redirectUri.length) {
        history.push("/home");
      } else {
        history.push(redirectUri);
      }
    } else history.push("/login#" + redirectUri);
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="code"
        label={user.translates("enter_sms_code")}
        name="code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label={user.translate("password")}
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <LoadingBox loading={loading} bottomGuther={1} noFade>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={sms}
          disabled={checkCode()}
          className={classes.submit}
        >
          {verify ? user.translate("verify") : user.translate("edit")}
        </Button>
      </LoadingBox>
      <Dialog
        open={success}
        onClose={goHome}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className="gradient">
          <DialogContent classes={{ root: classes.dialogContent }}>
            <div className={classes.closeIcon}>
              {/* <DialogTitle>{props.item.name}</DialogTitle> */}
              <IconButton
                edge="end"
                color="secondary"
                onClick={goHome}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DialogTitle className={classes.dialogTitle} disableTypography>
              {success}
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              className={classes.accept}
              onClick={goHome}
            >
              {isLoggedIn ? user.translate("home") : user.translate("login")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
