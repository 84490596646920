import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import { useRedirectLocationFromUrl } from "../common/useRedirectLocationFromUrl";

export const useStyles = makeStyles((theme) => ({
  "@keyframes slideUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  bottomOptions: {
    animation: "$slideUp 0.3s",
    animationFillMode: "both",
    animationDelay: 100,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
    "@media screen and (max-width: 375px)": {
      flexDirection: "column",
    },
  },
}));

export const LoginOptions = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.bottomOptions}>
      {children}
    </Grid>
  );
};

export const LoginOptionItem = ({ path, name }) => {
  const user = useContext(UserContext);

  const redirectUri = useRedirectLocationFromUrl();

  return (
    <Grid item>
      <Link
        to={redirectUri.length > 0 ? `${path}#${redirectUri}` : path}
        variant="body2"
      >
        {user.translate(name)}
      </Link>
    </Grid>
  );
};
