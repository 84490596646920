import React, { useContext } from 'react';
import { UserContext } from "../App";
import Add from '../../common/Add';

// React Function Component
export default function Profile(props) {

	const user = useContext(UserContext);

	const profile = user && user.me && user.me.user && user.me.user.profile ? user.me.user.profile : null;

	const EDIT_ITEM = `
    mutation ($userId: String!, $name: String!, $email: String!, $picture: String!, $birthday: String, $phone: String) {
        editProfile(input: {
          userId: $userId
          name: $name
          email: $email
          picture: $picture
          birthday: $birthday
          phone: $phone
        }){
          userId
        }
    }`;

	const fieldList = [
		{
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "userId",
			fieldLabel: "userId",
			fieldValue: profile.userId,
			error: false,
		},
		{
			required: false,
			type: "upload",
			fieldType: "upload",
			fieldName: "picture",
			fieldLabel: user.translate("image"),
			fieldValue: profile.picture,
		},
		{
			required: true,
			type: "text",
			fieldType: "text",
			fieldName: "name",
			fieldLabel: user.translate("name"),
			fieldValue: profile.name,
		},
		{
			required: true,
			type: "text",
			fieldType: "text",
			fieldName: "email",
			fieldLabel: user.translate("email"),
			fieldValue: profile.email,
		},
		{
			required: true,
			type: "text",
			fieldType: "text",
			fieldName: "phone",
			fieldLabel: user.translate("phone"),
			fieldValue: profile.phone,
		},
		{
			required: false,
			type: "dateyear",
			fieldType: "date",
			fieldName: "birthday",
			fieldLabel: user.translate("birthday"),
			fieldValue: profile.birthday,
			formatDate: true
		},
	];

	return (
		<Add
			fieldList={fieldList}
			openManipulateBox={props.setOpenProfile}
			actionType={user.translate("edit")}
			name={user.translate("profile")}
			manipulateItem={EDIT_ITEM}
			onSuccess={props.refetch}
			// checkStaff={true}
			// resturantId={props.resturantId}
		/>
	)

}
