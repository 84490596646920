import config from "../../../config";

export const getOrderOptions = ({ source, options }) => {
  const selItemIds = Object.fromEntries(
    options.map((it) => [it.id, it.quantity])
  );

  return source.options.flatMap((group) => {
    return group.items.map((item) => {
      return { item, orderQuantity: selItemIds[item.id] };
    });
  });
};

export const calcPrice = (cartItem) => {
  const discount = 1 - cartItem.source.discount;

  const selOptions = getOrderOptions(cartItem);

  const optionsPrice = selOptions.reduce(
    (acc, it) =>
      acc + (it.item.price || 0) * (it.orderQuantity ?? it.item.quantity),
    0
  );

  return optionsPrice * discount;
};

export const calcBasePrice = ({ source }) => {
  const discount = 1 - source.discount;

  const price = source.options.reduce((acc, { items, maxQuantity }) => {
    let groupQuantity = 0;

    const groupPrice = items.reduce((acc, { price, quantity }) => {
      if (groupQuantity >= maxQuantity) return acc;
      groupQuantity += quantity;
      return acc + price * quantity;
    }, 0);

    return acc + groupPrice;
  }, 0);

  return price * discount;
};

export const getBackgroundImageCss = (image) => {
  if (!image?.length) return null;

  let img = image;
  let webp = image.replace(".jpg", ".webp").replace(".png", ".webp");
  let png = image.replace(".webp", ".png").replace(".jpg", ".png");
  let jpg = image.replace(".webp", ".jpg").replace(".png", ".jpg");

  if (image.indexOf("http") === -1) {
    img = `${config.apiImagesUrl}/${img}`;
    webp = `${config.apiImagesUrl}/${webp}`;
    png = `${config.apiImagesUrl}/${png}`;
    jpg = `${config.apiImagesUrl}/${jpg}`;
  }

  return `-webkit-image-set( url('${webp}') 1x, url('${png}') 1x, url('${jpg}') 1x, url('${img}') 1x)`;
};
