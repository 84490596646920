import UserAuthForm from "./form/UserAuthForm";
import { LoginOptionItem, LoginOptions } from "./form/LoginOptions";
import LoginFacebook from "./facebook/LoginFacebook";
import LoginGoogle from "./google/LoginGoogle";
import { PasswordSignIn } from "./password/PasswordSignIn";

export default function SignIn(props) {
  return (
    <UserAuthForm title="signin" {...props}>
      <LoginGoogle />
      <LoginFacebook />
      <PasswordSignIn />

      <LoginOptions>
        <LoginOptionItem name="forgot_password" path="/forgot-password" />
        <LoginOptionItem name="no_account" path="/register" />
      </LoginOptions>
    </UserAuthForm>
  );
}
