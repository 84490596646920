import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { UserContext } from "views/App";

export default function useLoginWithFacebook(onLogin) {
  const CONNECT_FB = gql`
    mutation ($accessToken: String!) {
      auth_loginFacebook(accessToken: $accessToken) {
        ok
        message
        profile {
          name
          email
          id
        }
      }
    }
  `;

  const [loginWithFacebook, { loading }] = useMutation(CONNECT_FB);

  const user = useContext(UserContext);

  const loginWithFacebookAccessToken = useCallback(
    async (accessToken) => {
      if (accessToken) {
        const {
          data: { auth_loginFacebook: loginResult },
          errors,
        } = await loginWithFacebook({
          variables: { accessToken },
        });

        if (loginResult?.ok) {
          if (onLogin) onLogin();
        } else if (loginResult.message) {
          alert(loginResult.message);
        } else {
          alert(errors ?? user.translate("login_error"));
        }
      }
    },
    [loginWithFacebook, onLogin, user]
  );

  return { loginWithFacebookAccessToken, loading };
}
