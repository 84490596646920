import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  "@keyframes slideUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0%)",
    },
  },
  root: {
    // background: `url('${process.env.PUBLIC_URL}/images/background.webp')`,
    // backgroundRepeat: 'round',
    // backgroundSize: 'cover',
    position: "relative",
    width: "100%",
    height: "100vh",
  },
  container: {
    position: "relative",
    width: "100%",
    height: "100vh",
  },
  paper: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent", // theme.palette.secondary.main,
    width: 72,
    height: 72,
    borderRadius: "unset",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    animation: "$slideUp 0.3s",
    animationFillMode: "both",
  },
  bottomOptions: {
    animation: "$slideUp 0.3s",
    animationFillMode: "both",
    animationDelay: 100,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
    "@media screen and (max-width: 375px)": {
      flexDirection: "column",
    },
  },
  loading: { display: "flex", justifyContent: "center" },
  hidden: {
    display: "none",
  },
  error: {
    color: "red",
  },
  copyright: {
    position: "fixed",
    bottom: 0,
    margin: "0 auto",
    width: "100%",
  },
}));
