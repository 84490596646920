import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import SignInMini from "views/signin/SignInMini";
import { UserContext } from "../App";
import { PlaceContext } from "../home/Restaurant";
import { OrdersList } from "./cart/OrdersList";

// React Function Component
export default function Checkout(props) {
  const { cart } = props;

  const { placeId } = useParams();
  const history = useHistory();
  const place = useContext(PlaceContext);
  const user = useContext(UserContext);

  const [checkout, { loading: checkoutLoading, data: checkoutResult }] =
    useMutation(gql`
        mutation($posId: String!) {
            userMenu_makeOrder(
            posId: $posId
            finish: true
            order: {
                comment: ""
                orderType: "IN_RESTAURANT"
                items: ${JSON.stringify(place.cartItems).replace(
                  /"([^"]+)":/g,
                  "$1:"
                )}
            }) {
            error
            message
            }
        }
    `);

  useEffect((e) => {
    if (!checkoutLoading && checkoutResult != undefined && place != undefined) {
      const response = checkoutResult.userMenu_makeOrder;
      if (response.error == undefined && response.message == undefined) {
        history.replace(`/place/${place.place.id}/active-orders`);
      }
    }
  });

  if (!place.place) {
    return null;
  }

  let quantity = {};

  cart &&
    cart.items.map((cartItem) => {
      quantity[cartItem.id] = cartItem.quantity;
    });

  const sendOrder = () => {
    checkout({
      variables: {
        posId: place.place.id.toString(),
      },
    }).then(() => {
      place.finishOrder();
    });
  };

  const isLoading = checkoutLoading || cart.isLoading;
  console.log("cart", cart);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {cart.items.length === 0 && cart.time == null && (
        <>
          <Typography variant="h6">
            {user.translate("no_orders_made")}.
          </Typography>
          <br />
          <Typography>
            {user.translate("please_goto")}&nbsp;
            <Link href={`/place/${placeId}/menu`} color="primary">
              {user.translate("menu")}
            </Link>
          </Typography>
        </>
      )}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={600}
        width="100%"
        spacing={1}
      >
        {cart.items?.length ? <OrdersList items={cart.items} /> : null}
        {isLoading && (
          <Box pt={2}>
            <CircularProgress />
          </Box>
        )}

        {cart.items.length > 0 ? (
          user.me.isLoggedIn ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                sendOrder();
                e.stopPropagation();
              }}
              disabled={isLoading || cart.time == null || cart.isDirty}
            >
              {user.translate("confirm_order")}
            </Button>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <SignInMini title="login_to_confirm" refreshOnLogin />
              <Button
                variant="text"
                color="primary"
                sx={{ mt: 2 }}
                onClick={(e) => {
                  sendOrder();
                  e.stopPropagation();
                }}
                disabled={checkoutLoading || cart.time == null || cart.isDirty}
              >
                {user.translate("confirm_without_login")}
              </Button>
            </Box>
          )
        ) : (
          ""
        )}

        {!checkoutLoading &&
          checkoutResult != undefined &&
          checkoutResult.userMenu_makeOrder.error != undefined && (
            <div>
              Error:
              {checkoutResult.userMenu_makeOrder.error}
            </div>
          )}
        {!checkoutLoading &&
          checkoutResult != undefined &&
          checkoutResult.userMenu_makeOrder.message != undefined && (
            <div>{checkoutResult.userMenu_makeOrder.message}</div>
          )}
      </Stack>

      <p></p>
    </Box>
  );
}

Checkout.propTypes = {
  cart: PropTypes.any.isRequired,
};
