import { gql, useMutation, useQuery } from "@apollo/client";
import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment-timezone";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAnalytics } from "reactfire";
import * as translation_en from "../languages/en";
import * as translation_mk from "../languages/mk";
import "./App.scss";
import Home from "./home/Home";
import Language from "./home/Language";
import Restaurant from "./home/Restaurant";
import Questioner from "./menu/Questioner";
import ForgotPassword from "./signin/ForgotPassword";
import Register from "./signin/Register";
import ResetPassword from "./signin/ResetPassword";
import SignIn from "./signin/SignIn";

export const UserContext = createContext({
  lang: "mk",
});

window.columnParents = window.columnParents || new Object([]);
window.columnNumbers = window.columnNumbers || new Object([]);

const QUERY_ME = gql`
  query ($lang: String, $placeId: String) {
    userMenu_me(lang: $lang) {
      isLoggedIn
      lang
      languages
      user {
        id
        lang
        profile {
          userId
          name
          email
          picture
          fbAccessToken
          fbId
          birthday
          isAdmin
          phone
          isEmailVerified
        }
      }
    }
    userMenu_restaurants(lang: $lang, placeId: $placeId) {
      id
      name
      city
      imageLogo
      imageLogoBg
      address
      currency
      language
      phone
      places {
        id
        name
        webOrders
        tableOrders
        memberOrders
        webMenu
        orderTypes
        country
        language
        currency
      }
      scannedCode
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  app: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundImage: "url(/bg_4.jpg)",
    // backgroundRepeat: "repeat"
  },
}));

const PRIVACY_VERSION = 1;
const PRIVACY_COOKIE_NAME = `privacy${PRIVACY_VERSION}`;
const COOKIE_REGEX = new RegExp(
  `(?:(?:^|.*;\\s*)${PRIVACY_COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`
);
console.log(COOKIE_REGEX);

function App() {
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const classes = useStyles();

  const analytics = useAnalytics();

  const location = useLocation();
  const history = useHistory();

  const [language, setLanguage] = useState(null);
  const [privacyShown, setPrivacyShown] = useState(() =>
    document.cookie.replace(COOKIE_REGEX, "$1")
  );

  const onClosePrivacy = useCallback(() => {
    document.cookie = `${PRIVACY_COOKIE_NAME}=${new Date().getTime()};max-age=604800`;
    setPrivacyShown(true);
  }, []);

  const {
    called: fetchMeCalled,
    loading: loadingMe,
    data,
    refetch,
  } = useQuery(QUERY_ME, {
    variables: {
      lang: language,
      placeId: location.pathname.match(/place\/(\d+)/)?.get(1),
    },
    errorPolicy: "ignore",
  });

  const me =
    !loadingMe && data && data.userMenu_me
      ? data.userMenu_me
      : { languages: [] };
  const restaurants = data ? data.userMenu_restaurants : [];
  const scannedRestaurant = restaurants
    ?.filter((restaurant) => restaurant.scannedCode)
    ?.get(0);
  console.log("scannedRestaurant", scannedRestaurant);

  if (fetchMeCalled && loadingMe === false && me.lang != null) {
    if (language == null) {
      setLanguage(me.lang);
    }
  }

  const changeLanguage = (lang) => {
    refetch({
      lang: lang,
    });
    setLanguage(lang);

    analytics.logEvent("change_language", { lang: lang });
  };

  if (me.lang != null) {
    document.documentElement.setAttribute("lang", language);
  }

  const translation =
    language && language.toLowerCase() === "mk"
      ? translation_mk.default
      : translation_en.default;

  const translates = (key) => {
    var result = "";
    key = key.split(",");
    for (let i in key) {
      if (typeof key[i] === "string") {
        result += key[i] === " " ? " " : translation[key[i]];
      }
    }

    return result;
  };

  const consoleLog = (param, row = false) => {
    console.log(new Error().stack.split("\n")[2]);
    if ((me && me.user && me.user.profile && me.user.profile.isAdmin) || !me) {
      if (row) console.log(param, `row: ${row}`);
      else console.log(param);
    }
  };

  const onLogin = () => {
    refetch({
      variables: {
        lang: language,
      },
    });
    // console.log("result", result);
    // let path = location.hash && location.hash.replace("#", "");
    // history.push(path && path.length > 0 ? path : "/home");
  };

  const onRefetch = (onlyRefetch = false) => {
    refetch({
      variables: {
        lang: language,
      },
    });
    if (!onlyRefetch) {
      let path = location.hash && location.hash.replace("#", "");
      if (path && path.length > 0) {
        history.push(path);
      }
    }
  };

  const [scanCode, { loading: scanCodeLoading, data: scanCodeResult }] =
    useMutation(gql`
      mutation ($code: String!) {
        userMenu_useQR(code: $code) {
          message
          error
          placeId
        }
      }
    `);

  let lang = language || me.lang || "mk";

  let langNotLoaded = !fetchMeCalled || loadingMe;
  let haveLangOrNotLoaded = langNotLoaded || language;

  const isLoading =
    me.lang == null && language == null && (loadingMe || !fetchMeCalled);

  const locationHash = location.hash;

  const hashScanCode = useMemo(() => {
    const hashParams = locationHash.replace("#", "").split("&");

    for (const param of hashParams) {
      const [key, value] = param.split("=");
      if (key === "code" && value) {
        console.log("SCANN", value);
        return value;
      }
    }
  }, [locationHash]);

  useEffect(() => {
    if (!hashScanCode || isLoading) return;

    scanCode({
      variables: {
        code: hashScanCode,
      },
    })
      .then(({ data: scanCodeResult }) => {
        console.log("scanCodeResult", scanCodeResult);
        const placeId = scanCodeResult?.userMenu_useQR?.placeId;
        console.log("placeId", placeId);
        if (placeId) {
          history.push(`/place/${placeId}/menu`);
        } else {
          history.replace("#");
        }
      })
      .catch((e) => {
        console.log("error", e);
        history.replace("#");
      });
  }, [hashScanCode, history, isLoading, scanCode]);

  if (hashScanCode) return <LinearProgress />;

  if (me.lang == null && language == null && (loadingMe || !fetchMeCalled)) {
    return <LinearProgress />;
  }

  const chunk = (arr, size) =>
    arr.reduce(
      (acc, e, i) => (
        i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
      ),
      []
    );

  let dateTimeFormatDisplay = "DD.MM.yyyy HH:mm";
  let dateFormatDisplay = "DD.MM.yyyy";
  let dateTimeFormat = "dd.MM.yyyy HH:mm";
  let dateFormat = "dd.MM.yyyy";

  const formatDate = (date, datetime = false, format = false) => {
    if (format)
      return moment(date)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(format);
    else if (datetime)
      return moment(date)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(dateTimeFormatDisplay);
    else
      return moment(date)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(dateFormatDisplay);
  };

  return (
    <UserContext.Provider
      value={{
        me: me || undefined,
        profile: me && me.user && me.user.profile ? me.user.profile : undefined,
        lang: lang,
        consoleLog,
        chunk: chunk,
        formatDate: formatDate,
        dateFormat: dateFormat,
        dateTimeFormat: dateTimeFormat,
        translate: (key) => translation[key] ?? key,
        translates: translates,
      }}
    >
      <div className={`App ${classes.app}`}>
        <Switch>
          <Route exact={true} path="/">
            <Redirect
              to={{
                pathname: haveLangOrNotLoaded
                  ? "/home"
                  : `/language#${location.pathname}`,
              }}
            />
          </Route>
          <Route path="/language">
            <Language
              isLoading={langNotLoaded}
              languages={me.languages}
              setLanguage={changeLanguage}
              scannedRestaurant={scannedRestaurant}
            />
          </Route>
          <Route path="/language#*">
            <Language
              isLoading={langNotLoaded}
              languages={me.languages}
              setLanguage={changeLanguage}
              scannedRestaurant={scannedRestaurant}
            />
          </Route>
          <Route path="/language/*">
            <Language
              isLoading={langNotLoaded}
              languages={me.languages}
              setLanguage={changeLanguage}
              scannedRestaurant={scannedRestaurant}
            />
          </Route>
          <Route path="/home">
            {haveLangOrNotLoaded ? (
              <Home
                restaurants={restaurants}
                languages={me.languages}
                setLanguage={changeLanguage}
                refetch={onRefetch}
              />
            ) : (
              <Redirect to={`/language#${location.pathname}`} />
            )}
          </Route>
          <Route exact={true} path="/login">
            <SignIn onLogin={onLogin} />
          </Route>
          <Route exact={true} path="/forgot-password">
            <ForgotPassword onLogin={onLogin} />
          </Route>
          <Route exact={true} path="/register">
            <Register onLogin={onLogin} />
          </Route>
          <Route exact={true} path="/verify-phone/:verifier">
            <ResetPassword onLogin={onLogin} verify={true} />
          </Route>
          <Route path="/reset-pass/:verifier">
            <ResetPassword onLogin={onLogin} verify={false} />
          </Route>
          <Route path="/reset-pass-logged/:verifier">
            <ResetPassword onLogin={onLogin} verify={false} isLoggedIn={true} />
          </Route>
          <Route exact path="/place/:placeId">
            {haveLangOrNotLoaded ? (
              <Restaurant restaurants={restaurants} refetch={onRefetch} />
            ) : (
              <Redirect to={{ pathname: `/language#${location.pathname}` }} />
            )}
          </Route>
          <Route path={"/place/:placeId/questioner/:questionerId"}>
            <Questioner />
          </Route>
          <Route path="/place/:placeId/:drawerMenu">
            {haveLangOrNotLoaded ? (
              <Restaurant restaurants={restaurants} refetch={onRefetch} />
            ) : (
              <Redirect to={{ pathname: `/language#${location.pathname}` }} />
            )}
          </Route>
          <Route>
            <p>Page Not Found</p>
          </Route>
        </Switch>
      </div>

      {!privacyShown && (
        <Box position="fixed" bottom={0} padding={1} zIndex={9999}>
          <Paper
            elevation={6}
            sx={{
              bgcolor: "darkslategray",
              p: 2,
              mb: -2,
              position: "relative",
            }}
          >
            <Typography variant="subtitle1">Полиса за приватност</Typography>
            <Typography variant="caption">
              Со користењето на овој сајт, вие се согласувате на нашата{" "}
              <Link href="https://www.e-bar.mk/privacy.html">
                полиса за приватност
              </Link>
            </Typography>
            <IconButton
              position="absolute"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={onClosePrivacy}
            >
              <Close />
            </IconButton>
          </Paper>
        </Box>
      )}
    </UserContext.Provider>
  );
}

export default App;
