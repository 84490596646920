import { gql, useQuery } from "@apollo/client";

const gqlQuery = `query($posId: String!, $finished: Boolean!) {
    userMenu_orders(posId: $posId, finished: $finished) {
      error
      message
      receipts {
        id
        created
        employee
        customer
        total
        discount
        created
        closed
        status
        table
        orders {
          id
          title
          comment
          quantity
          price
          created
          status
          menuItemId
        }
        questioner {
          id
          title
          items {
            id
            title
            type
            optional
          }
        }
      }
    }
  }
`;

export const useOrdersQuery = (placeId, finished) => {
  return useQuery(gql(gqlQuery), {
    errorPolicy: "ignore",
    pollInterval: 3000,
    skip: placeId == null,
    variables: {
      posId: placeId?.toString() ?? "",
      finished: finished || false,
    },
  });
};
