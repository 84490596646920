import UserAuthForm from "./form/UserAuthForm";
import { LoginOptionItem, LoginOptions } from "./form/LoginOptions";
import LoginFacebook from "./facebook/LoginFacebook";
import LoginGoogle from "./google/LoginGoogle";
import { PasswordForgot } from "./password/PasswordForgot";

export default function ForgotPassword(props) {
  return (
    <UserAuthForm {...props} title="forgot_password">
      <LoginGoogle />
      <LoginFacebook />
      <PasswordForgot />

      <LoginOptions>
        <LoginOptionItem name="have_account" path="login" />
      </LoginOptions>
    </UserAuthForm>
  );
}
