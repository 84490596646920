import { Button } from "@mui/material";
import { useContext } from "react";
import { useLogin } from "react-facebook";
import { UserContext } from "../../App";
import LoadingBox from "../common/LoadingBox";
import useOnLogin from "../common/useOnLogin";
import useLoginWithFacebook from "./useLoginWithFacebook";
import { Facebook } from "@mui/icons-material";

export default function LoginFacebook(props) {
  const user = useContext(UserContext);

  const onLogin = useOnLogin(props);

  const { loginWithFacebookAccessToken, loading } =
    useLoginWithFacebook(onLogin);

  const { login, status, isLoading, error } = useLogin();

  async function handleLogin() {
    try {
      const response = await login({
        scope: "email",
      });

      if (response.authResponse?.accessToken) {
        loginWithFacebookAccessToken(response.authResponse.accessToken);
      } else {
        props.onError(
          user.translate(response.authResponse?.message ?? "error")
        );
      }
    } catch (error) {
      props.onError(error.message ?? user.translate("login_error"));
    }
  }

  return (
    <LoadingBox loading={loading || isLoading} bottomGuther={1}>
      <Button
        variant="contained"
        color="info"
        onClick={handleLogin}
        sx={{ width: 320 }}
        startIcon={<Facebook />}
      >
        {user.translate("register_fb")}
      </Button>
    </LoadingBox>
  );
}
