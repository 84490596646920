import { gql, useMutation } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../App";
import LoadingBox from "../common/LoadingBox";
import useOnLogin from "../common/useOnLogin";
import { useStyles } from "./styles";
import helper from "common/Helper";

export const PasswordSignIn = (props) => {
  const classes = useStyles();

  const user = useContext(UserContext);

  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [password, setPassword] = useState("");

  const [login, { loading: loginLoading }] = useMutation(
    gql`
      mutation ($email: String!, $phone: String!, $password: String!) {
        auth_login(email: $email, phone: $phone, password: $password) {
          ok
          message
          profile {
            id
            isEmailVerified
            name
          }
        }
      }
    `,
    {
      errorPolicy: "ignore",
    }
  );

  const checkLoginFields = () => {
    if (loginLoading) return true;

    if (phoneOrEmail !== "") {
      let regEmail = /\S+@\S+\.\S+/;
      let regPhone = /^\+?[0-9]+$/g;

      if (
        !regEmail.test(String(phoneOrEmail).toLowerCase()) &&
        !regPhone.test(String(phoneOrEmail))
      ) {
        return true;
      }
    }

    return phoneOrEmail !== "" && password !== "" ? false : true;
  };

  const onLogin = useOnLogin(props);

  const onLoginClick = async (e) => {
    e.preventDefault();

    props.onStartLoading();

    try {
      const variables =
        phoneOrEmail.indexOf("@") > -1
          ? {
              email: phoneOrEmail,
              phone: "",
              password: password,
            }
          : {
              email: "",
              phone: helper.getPhone(phoneOrEmail),
              password: password,
            };
      const { data: loginResult, error: loginError } = await login({
        variables,
      });

      if (loginResult != null && loginError == null) {
        if (loginResult.auth_login.ok) {
          onLogin();
        } else {
          props.onError(loginResult.auth_login.message);
        }
      } else if (loginError != null) {
        props.onError(user.translate("login_error"));
      } else {
        console.debug("Unexpected case");
        props.onError(user.translate("login_error"));
      }
    } catch (e) {
      props.onError(e.message);
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="none"
        required
        fullWidth
        id="phone_or_email"
        label={user.translates("phone, ,or, ,email_address")}
        name="phone_or_email"
        autoComplete="phone email"
        autoFocus
        value={phoneOrEmail}
        onChange={(e) => setPhoneOrEmail(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="none"
        required
        fullWidth
        name="password"
        label={user.translate("password")}
        type="password"
        id="password"
        autoComplete="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormControlLabel
        margin="none"
        control={<Checkbox value="remember" color="primary" />}
        label={user.translate("remember_me")}
      />

      <LoadingBox loading={loginLoading} bottomGuther={1} noFade>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onLoginClick}
          disabled={checkLoginFields()}
          className={classes.submit}
        >
          {user.translate("signin")}
        </Button>
      </LoadingBox>
    </>
  );
};
