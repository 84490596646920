import React, { useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../App';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    dialog: {
        borderRadius: theme.spacing(2),
		position: "relative"
    },
	dialogContent: {
		padding: `${theme.spacing(2)} !important`
	},
	closeIcon: {
		position: 'absolute',
        top: 0,
        right: 12,
		"& h2": {
			fontSize: 14,
			textTransform: "uppercase"
		},
	},
	dialogTitle: {
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(1),
	},
	accept: {
		flexGrow: 1,
		margin: `${theme.spacing(2)} ${theme.spacing(2)} 0px ${theme.spacing(2)}`
	},
}));

export default function RequestPassword(props) {

	const classes = useStyles();
	const user = useContext(UserContext);

	const history = useHistory();

	const [requestIt, setRequestIt] = useState(false);

	const REQUEST = gql`
    mutation {
        auth_requestResetPassword(platform: WEB_MENU, email: "") {
            message
            ok
			verifier
        }
    }`;

	const [requestPassword, { loading: loading_requestPass, data: data_requestPass }] = useMutation(REQUEST);

	const handleClose = () => {
        props.setOpenPassword(false);
	};

	const handleRequestAccept = () => {
		requestPassword();
		setRequestIt(true);
    };

    if(requestIt && data_requestPass && !loading_requestPass) 
		history.push('/reset-pass-logged/'+data_requestPass.auth_requestResetPassword.verifier);

	return (
		<>
			<Dialog
				open={!requestIt}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{paper: classes.dialog}}
			>
				<div className="gradient">
					<DialogContent classes={{root: classes.dialogContent}}>
						<div className={classes.closeIcon}>
							{/* <DialogTitle>{props.item.name}</DialogTitle> */}
							<IconButton edge="end" color="secondary" onClick={handleClose} aria-label="close">
								<CloseIcon />
							</IconButton>
						</div>
						<DialogTitle className={classes.dialogTitle} disableTypography>{props.message} {props.name}</DialogTitle>
					</DialogContent>
					<DialogActions>
						<Button
                            variant="outlined"
                            color="primary"
                            className={classes.accept}
                            onClick={handleRequestAccept}
                        >
                            {user.translate("accept")}
                        </Button>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
}
