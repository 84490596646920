import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesOutlinedIcon from "@mui/icons-material/Notes";
import { Box, ButtonBase, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import { PlaceContext } from "../../home/Restaurant";
import { calcBasePrice, calcPrice, getBackgroundImageCss } from "./utils";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircleIcon from "@mui/icons-material/Circle";


const Title = ({ cartItem, selected }) => {
  const history = useHistory();
  const place = useContext(PlaceContext);

  const onOpenProduct = useCallback(() => {
    history.push(`/place/${place.place.id}/menu/${cartItem.id}`);
  }, [history, cartItem.id, place.place.id]);

  return (
    <ButtonBase
      onClick={(e) => onOpenProduct(cartItem)}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        pl: 1,
        height: 36,
        width: "100%",
        paddingRight: 4,
        transition: "all 0.5s ease",
        marginBottom: selected ? 0 : -3.5,
        paddingLeft: selected ? 1 : "88px",
        maxWidth: "calc(100vw - 36px)",
      }}
      disabled
    >
      <Typography
        variant="subtitle2"
        textAlign="left"
        width="100%"
        color="#FFF"
        sx={{
          display: "inline-block",
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {cartItem.source.name.capitalize()}
      </Typography>
    </ButtonBase>
  );
};

const ProductImage = ({ cartItem }) => {
  return (
    <Box
      width={72}
      height={72}
      borderRadius={2}
      boxShadow="0 4px 20px rgba(0, 0, 0, 0.1)"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: getBackgroundImageCss(cartItem.source.image),
        backgroundColor: "#FFFFFF44",
      }}
    >
      &nbsp;
    </Box>
  );
};

export const formatCurrency = (value) => {
  return Math.round(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const PriceText = ({ value, color, bold }) => {
  const user = useContext(UserContext);
  return (
    <Typography
      variant="subtitle2"
      color={color}
      textTransform="lowercase"
      fontWeight={bold ? 600 : 400}
    >
      {formatCurrency(value)} {user.translate("den")}
    </Typography>
  );
};

const ProductPrice = ({ basePrice, additonsPrice }) => {
  if (additonsPrice) {
    return (
      <Stack direction="row">
        <PriceText value={basePrice} bold />
        <Typography
          variant="subtitle2"
          color="secondary"
          fontWeight={600}
          ml={1}
          mr={0.2}
        >
          +
        </Typography>
        <PriceText value={additonsPrice} color="secondary" />
      </Stack>
    );
  }

  return <PriceText value={basePrice} bold />;
};

const OrderPrice = ({ price, selected }) => {
  const user = useContext(UserContext);

  return (
    <Typography
      variant="subtitle2"
      color="secondary"
      textTransform="lowercase"
      pt={0.5}
      sx={{
        position: "absolute",
        width: 80,
        transition: "all 0.5s ease",
        mt: selected ? 0 : -2,
        opacity: selected ? 1 : 0,
      }}
    >
      {formatCurrency(price)} {user.translate("den")}
    </Typography>
  );
};

const SelectedProductOption = ({ item }) => {
  const user = useContext(UserContext);

  return (
    <Box
      key={item.id}
      display="flex"
      flexGrow={1}
      color="#d6d6d6"
      mr={1}
      alignItems="center"
    >
      {item.mainProduct ? (
        <ChevronRightIcon
          fontSize="small"
          sx={{ fontSize: 18, ml: "-6px", pb: "1px", float: "left" }}
        />
      ) : (
        <CircleIcon
          fontSize="small"
          sx={{ fontSize: 7, pb: "2px", pl: 0.5, pr: 1, float: "left" }}
        />
      )}
      <Typography
        textTransform={item.mainProduct ? "uppercase" : "none"}
        fontSize={item.mainProduct ? 12 : 10}
        variant="caption"
        textAlign="left"
        textOverflow="ellipsis"
        lineHeight="1.2 !important"
        style={{ textWrap: "balance" }}
      >
        {item.name}
      </Typography>

      {item.quantity ? (
        <>
          <Typography
            variant="caption"
            pl={1}
            pr="1px"
            pb="1px"
            fontWeight="bold"
            lineHeight={1}
          >
            x
          </Typography>
          <Typography
            variant="caption"
            fontWeight="bold"
            lineHeight={1}
            whiteSpace="nowrap"
          >
            {item.quantity}
          </Typography>
        </>
      ) : null}
      <Typography
        variant="caption"
        textAlign="right"
        textOverflow="ellipsis"
        lineHeight={1.4}
        flexGrow={1}
        pl={1}
        style={{ textWrap: "nowrap" }}
      >
        {formatCurrency(item.price)} {user.translate("den")}
      </Typography>
    </Box>
  );
};

const OrderSelectedOptions = ({ cartItem }) => {
  const selectedOptions = useMemo(() => {
    return cartItem.source.options.flatMap((option) => {
      return option.items.filter((item) => {
        return cartItem.options.find((it) => it.id === item.id);
      });
    });
  }, [cartItem.options, cartItem.source.options]);

  return (
    <Stack direction="column" width="100%">
      {selectedOptions.map((item) => (
        <SelectedProductOption key={item.id} item={item} />
      ))}
    </Stack>
  );
};

const OrderComment = ({ cartItem: { comment } }) => {
  if (!comment?.length) return null;

  return (
    <Typography
      variant="caption"
      lineHeight={1.4}
      textAlign="left"
      fontStyle="italic"
      color="#acacac"
      mr={1}
    >
      <NotesOutlinedIcon
        fontSize="small"
        sx={{ mr: 0.5, fontSize: 15, float: "left" }}
      />
      {comment}
    </Typography>
  );
};

const OrderQuantity = ({ cartItem }) => {
  const place = useContext(PlaceContext);

  const updateQuantity = (cartItem, relativeValue) => {
    place.updateItem(
      cartItem.id,
      cartItem.quantity + relativeValue,
      cartItem.comment,
      cartItem.options
    );
  };

  return (
    <Box flexDirection="row" display="flex" alignItems="center" pl={1}>
      <IconButton
        color="secondary"
        width={36}
        height={36}
        borderRadius="50%"
        disabled={cartItem.quantity < 2}
        onClick={(e) => {
          updateQuantity(cartItem, -1);
          e.stopPropagation();
        }}
      >
        &#8722;
      </IconButton>
      <Typography variant="h6" marginLeft={1} marginRight={1} width={36}>
        {cartItem.quantity}
      </Typography>
      <IconButton
        color="secondary"
        width={36}
        height={36}
        borderRadius="50%"
        onClick={(e) => {
          updateQuantity(cartItem, 1);
          e.stopPropagation();
        }}
      >
        &#43;
      </IconButton>
    </Box>
  );
};

const OrderTotalPrice = ({ cartItem, price }) => {
  return <PriceText value={price * cartItem.quantity} bold />;
};

const OrderDeleteButton = ({ cartItem }) => {
  const place = useContext(PlaceContext);
  const user = useContext(UserContext);

  const onRemoveItem = useCallback(
    (cartItem) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(user.translate("confirm_remove_order"))) {
        place.removeItem(cartItem.id);
      }
    },
    [place, user]
  );

  return (
    <IconButton
      sx={{ position: "absolute", top: 0, right: 0 }}
      color="secondary"
      onClick={(e) => {
        e.stopPropagation();
        onRemoveItem(cartItem);
      }}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

// React Function Component
export default function OrederItem({ cartItem }) {
  const basePrice = useMemo(() => calcBasePrice(cartItem), [cartItem]);
  const orderPrice = useMemo(() => calcPrice(cartItem), [cartItem]);

  const hasAddition = basePrice !== orderPrice;

  const price = useMemo(() => {
    if (cartItem.options?.length) {
      return orderPrice;
    } else {
      return basePrice;
    }
  }, [basePrice, cartItem.options?.length, orderPrice]);

  const [selected, setSelected] = useState(false);

  return (
    <Stack
      key={cartItem.id}
      width="100%"
      borderRadius={2}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      backgroundColor={selected ? "#FFFFFF28" : "#FFFFFF11"}
      boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
      border="1px solid rgba(255, 255, 255, 0.08)"
      style={{
        backdropFilter: "blur(6px)",
        webkitBackdropFilter: "blur(6px)",
        transition: "all 0.5s ease",
      }}
      onClick={() => setSelected((val) => !val)}
    >
      <Title cartItem={cartItem} selected={selected} />
      <Stack
        width="100%"
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box paddingLeft={1} paddingBottom={1}>
          <ProductImage cartItem={cartItem} />
          {hasAddition ? (
            <OrderPrice price={price} selected={selected} />
          ) : null}
        </Box>
        <Box
          flexDirection="column"
          display="flex"
          alignItems="start"
          flexGrow={1}
        >
          <Box
            sx={{ transition: "all 0.5s ease", mt: selected ? 0 : 2, pt: 0.2 }}
          />
          <ProductPrice
            basePrice={basePrice}
            additonsPrice={orderPrice - basePrice}
          />
          <Stack
            direction="column"
            width="100%"
            sx={{
              transition: "all 0.5s ease",
              minHeight: selected && hasAddition ? 50 : 0,
              maxHeight: selected ? 200 : 0,
              opacity: selected ? 1 : 0,
            }}
          >
            <div
              style={{
                fontSize: 12,
                textAlign: "left",
                marginBottom: 4,
                fontStyle: "italic",
                "& p": {
                  margin: "0px",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: cartItem.source.description
                  .replaceAll("<p>", "<span>")
                  .replaceAll("<p ", "<span")
                  .replaceAll("</p>", "</span> ")
                  .replace(/(<? *script)/gi, "illegalscript"),
              }}
            ></div>
            <OrderSelectedOptions cartItem={cartItem} />
            <OrderComment cartItem={cartItem} />
          </Stack>

          <Box
            flexDirection="row"
            display="flex"
            alignSelf="center"
            alignItems="center"
            justifyContent="space-between"
            pr={2}
            width="100%"
          >
            <OrderQuantity cartItem={cartItem} />

            <OrderTotalPrice cartItem={cartItem} price={price} />
          </Box>
        </Box>
      </Stack>
      <OrderDeleteButton cartItem={cartItem} />
    </Stack>
  );
}

OrederItem.propTypes = {
  cartItem: PropTypes.any.isRequired,
};
