import { gql, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "views/App";
import { useOrdersQuery } from "./gql/useOrdersQuery";
import { useHistory } from "react-router-dom";

export default function Questioner(props) {
  const ratings = {};

  const history = useHistory();
  const user = useContext(UserContext);
  const { placeId, questionerId } = useParams();

  const { loading, data: orderResult } = useOrdersQuery(placeId, false);

  const receipts = orderResult?.userMenu_orders?.receipts;

  const questionerReceipt = receipts?.find(
    (receipt) => receipt.questioner != null
  );
  const questioner = questionerReceipt?.questioner;

  const receiptId = questionerReceipt?.id;

  const [answers, setAnswers] = useState({});
  const [mesg, setMsg] = useState(false);

  const handleClose = () => {
    setMsg(false);
    history.push(`/place/${placeId}/active-orders`);
  };

  useEffect(() => {
    if (!loading && !questioner) {
      history.push(`/place/${placeId}/active-orders`);
    }
  }, [history, loading, placeId, questioner]);

  const [submitReview] = useMutation(
    gql`
    mutation (
      $receiptId: String!
      $questionerId: String!
    ) {
      userMenu_submitReview(
        receiptId: $receiptId
        questionerId: $questionerId
        items: ${JSON.stringify(Object.values(answers)).replace(
          /"([^"]+)":/g,
          "$1:"
        )}
      ) {
        error
        message
      }
    }`,
    {
      variables: {
        receiptId,
        questionerId,
      },
      onCompleted: (data) => {
        data.userMenu_submitReview?.message != null
          ? setMsg(data.userMenu_submitReview?.message)
          : setMsg(data.userMenu_submitReview?.error);

        console.log(data, "data");
      },
    }
  );

  return (
    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}>
      <Typography variant="h6">{questioner?.title}</Typography>
      <form
        onSubmit={() => {
          submitReview({
            variables: {
              items: ratings,
            },
          });
        }}
        style={{ marginTop: 60 }}
      >
        {questioner?.items?.map((question, index) => {
          return (
            <div key={index} style={{ marginTop: 16 }}>
              <Typography>{question.title}</Typography>
              <div>
                <Rating
                  key={question.id}
                  name={`customized-10${index}`}
                  value={answers[question.id]?.value ?? 0}
                  precision={1}
                  max={5}
                  size="large"
                  emptyIcon={
                    <StarBorderIcon
                      style={{
                        opacity: 0.55,
                        color: "#FFEA00",
                        height: 30,
                        width: 30,
                      }}
                      fontSize="large"
                    />
                  }
                  icon={
                    <StarIcon
                      style={{ colot: "#FFEA00", height: 30, width: 30 }}
                      fontSize="large"
                    />
                  }
                  onChange={(event, newValue) => {
                    setAnswers((answers) => ({
                      ...answers,
                      [question.id]: {
                        id: question.id,
                        value: newValue,
                        comment: "",
                      },
                    }));
                  }}
                />
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 50 }}>
          <Button
            variant="outlined"
            onClick={() => {
              submitReview();
            }}
            disabled={Boolean(
              questioner?.items?.filter(
                ({ id, optional }) => !optional && answers[id]?.value == null
              )?.length ?? true
            )}
          >
            {user.translate("submit")}
          </Button>
          <Dialog
            open={!!mesg}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="gradient">
              <div>
                <IconButton
                  edge="end"
                  color="secondary"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {mesg}
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
        </div>
      </form>
      <p></p>
    </div>
  );
}
