import { gql, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import helper from "common/Helper";
import { UserContext } from "../../App";
import LoadingBox from "../common/LoadingBox";
import { useStyles } from "./styles";

export const PasswordRegister = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const user = useContext(UserContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [register, { loading: isLoading }] = useMutation(
    gql`
      mutation ($email: String!, $name: String!, $phone: String!) {
        auth_register(
          email: $email
          name: $name
          phone: $phone
          platform: WEB_MENU
        ) {
          ok
          message
          verifier
        }
      }
    `,
    {
      errorPolicy: "ignore",
    }
  );

  const checkRegisterFields = () => {
    if (isLoading || props.isLoading) return true;

    if (email !== "") {
      let regEmail = /\S+@\S+\.\S+/;

      if (!regEmail.test(String(email).toLowerCase())) {
        return true;
      }
    }

    if (phone !== "") {
      let regPhone = /^\+?[0-9]+$/g;

      if (!regPhone.test(String(phone))) {
        return true;
      }
    }

    return email !== "" && phone !== "" && name !== "" ? false : true;
  };

  const onRegisterClick = async (e) => {
    e.preventDefault();

    props.onStartLoading();

    try {
      const { data, error: registerError } = await register({
        variables: {
          email: email,
          phone: helper.getPhone(phone),
          name: name,
        },
      });

      if (data) {
        if (data.auth_register.ok) {
          if (data.auth_register.message.length > 0) {
            alert(data.auth_register.message);
          }
          // onLogin();
          history.push("/verify-phone/" + data.auth_register.verifier);
        } else {
          props.onError(data.auth_register.message);
        }
      } else if (registerError != null) {
        props.onError(user.translate("register_error"));
      }
    } catch (e) {
      props.onError(e.message);
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label={user.translate("name")}
        name="name"
        autoComplete="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phone"
        label={user.translate("phone")}
        name="phone"
        autoComplete="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="phone"
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={user.translate("email_address")}
        name="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
      />

      <LoadingBox loading={isLoading} bottomGuther={1} noFade>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onRegisterClick}
          disabled={checkRegisterFields()}
          className={classes.submit}
        >
          {user.translate("register")}
        </Button>
      </LoadingBox>
    </>
  );
};
