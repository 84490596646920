import React, { useState, useContext } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    IconButton
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import Form from "./Form";
import SnackBarAdd from './SnackBarAdd';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from "../views/App";

const useStyles = makeStyles(theme => ({
    dialog: {
        borderRadius: theme.spacing(2)
    },
    closeIcon: {
		padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
		position: 'absolute',
        top: 0,
        right: 0,
		"& h2": {
			fontSize: 14,
			textTransform: "uppercase"
		},
	},
    dialogTitle: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
        fontSize: 16,
    },
    dialogContent: {
        padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
    },
    form: {
        "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormControlLabel-label": {
            fontSize: 14
        },
    },
    accept: {
		flexGrow: 1,
		margin: `${theme.spacing(2)} ${theme.spacing(2)} 0px ${theme.spacing(2)}`
	},
}));

export default function Add(props) {

    const classes = useStyles();
    const user = useContext(UserContext);
    const fieldList = props.fieldList;

    let returnObj = {}, stateObjVals = {};

    Object.keys(fieldList).map(index => {
        if(fieldList[index].type === "autocomplete")
            returnObj[fieldList[index].fieldName] = fieldList[index].fieldValue.val;
        else if(fieldList[index].type === "autocompleteAdd") {
            returnObj[fieldList[index].fieldName] = fieldList[index].fieldValue.val;
        }
        else returnObj[fieldList[index].fieldName] = fieldList[index].fieldValue;
        if(fieldList[index].required && fieldList[index].fieldValue === "")
            fieldList[index].error = true;
        stateObjVals[index] = fieldList[index];

        return fieldList[index];
    });

    const [stateObj, setStateObj] = useState(stateObjVals);
    const [returnItems, setReturnItems] = useState({...returnObj});
    const [refresh, setRefresh] = useState(props.refresh || false);
    const [manipulateIt, setManipulateIt] = useState(false);

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(props.openSnackBarWarning);
    
    const [onSuccess, setOnSuccess] = useState(props.onSuccess !== undefined);

    const [changeIt, setChangeIt] = useState(props.checkStaff || false);

    const [query, setQuery] = useState(props.manipulateItem);
    const [manipulateItem, { loading: loading_add, data: data_add}] = useMutation(gql`${query}`);
    
    const [checkEmail, setCheckEmail] = useState(false);

    let line = props.line || false;
    let checkStaff = props.checkStaff || false;

    const CHECK_STAFF = gql`
        query ($email: String!, $restaurantId: String!){
            extras_getProfileByEmail(email: $email, restaurantId: $restaurantId) {
                _id
                id
                email
                name
                role
            }
        }
    `;
    const [getStaff, { data: data_profile, loading: loading_profile }] = useLazyQuery(CHECK_STAFF, {
        fetchPolicy: "cache-and-network", 
        skip: !checkEmail,
    });

    let staff = {};

    if(loading_profile)
    {
        return (<div className="App AppLoading"><CircularProgress /></div>);
    }
    
    if(data_profile && checkEmail)
    {
        staff = data_profile.extras_getProfileByEmail;
        user.consoleLog(staff)
        if(staff && staff.id)
        {
            if(staff.role === -1)
            {
                setReturnItems({...returnItems,
                    name: staff.name,
                    email: staff.email,
                    employeeId: staff.id,
                });
                setQuery(props.manipulateItem1);
            }
            else {
                let _stateObj = Object.assign(stateObj);
                    _stateObj[1].error = true;
                    _stateObj[1].helperText = user.translate("restaurant_employee_exists");

                setStateObj(_stateObj);

                setManipulateIt(false);
                setRefresh(true);
            }
        } else {
            setQuery(props.manipulateItem);
        }

        setCheckEmail(false);
    }

    if(manipulateIt && changeIt && props.manipulateItem1 && !loading_profile && checkEmail === false)
    {
        manipulateItem({ variables: returnItems });
        setOpenSnackBar(true);
        setChangeIt(false);
    }

    // Add Dialog Close
    const handleClose = () => {
        if (!openSnackBar) {
            if(props.setOpenSnackBarWarning)
            {
                setOpenSnackBarWarning(false);
                props.setOpenSnackBarWarning(false)
            }
            props.openManipulateBox(false);
        }
    };

    if(!loading_add && !loading_profile  && manipulateIt && onSuccess && !changeIt) {
        user.consoleLog("Manipulation done!");
        if(props.returnParams && data_add) {
            props.onSuccess(data_add);
        }
        else props.onSuccess();
        setOnSuccess(false);
    }

    if (manipulateIt && !loading_add && !onSuccess) {
        handleClose();
    }

    // If it is loading_add, show progress bar
    if (loading_add) {
        return (<div className="App AppLoading"><CircularProgress /></div>);
    }

    const setItemsFunction = (name, value, change) => {

        let _returnItems = {...returnItems};

        if(typeof name === "object")
        {
            for(var i in name)
            {
                _returnItems[name[i]] = value[i];
            }
            setReturnItems({..._returnItems});
            if(typeof change !== "undefined")
            {
                if(props.manipulateItem1) setChangeIt(true);
            }
            else setChangeIt(false);
        }
        else {
            _returnItems[name] = value;
            setReturnItems({..._returnItems});
        }

        user.consoleLog({..._returnItems});
    }

    const checkErrorFunction = () => {

        for (let index in stateObj) {
            if (stateObj[index].required && stateObj[index].error) {
                return true;
            }
        }

        if(openSnackBarWarning)
        {
            return true;
        }

        return false;
    }

    const enterManipulateAccept =(event) => {

        event.preventDefault();
        handleManipulateAccept(event);
    }

    const handleManipulateAccept = (e) => {

        e.preventDefault();

        if (!checkErrorFunction()) {
            checkFields();
            setManipulateIt(true);
            if(!changeIt)
            {
                manipulateItem({ variables: returnItems });
                setOpenSnackBar(true);
            }
            else {
                if(checkStaff && props.restaurantId)
                {
                    getStaff({variables: { email: returnItems['email'], restaurantId: props.restaurantId}});
                    setCheckEmail(true);
                }
                else {
                    setQuery(props.manipulateItem1)
                }
            }
        }
        
        user.consoleLog(returnItems)
    }

    const checkFields = () => {

        let setLangId = false;
        let _returnItems = Object.assign(returnItems);

        user.consoleLog(returnItems)

        if(props.setAddGroup) props.setAddGroup(false);

        let multiplier = true;

        if(returnItems.uom && returnItems.uom === 2)
        {
            multiplier = false;
        }

        fieldList.forEach(fieldItem => {
            if(fieldItem.null !== "undefinded" && returnItems.name === "")
            {
                setLangId = true;
            }
        });
        
        fieldList.forEach(fieldItem => {
            if(fieldItem.nullLangId !== "undefinded" && setLangId && props.setAddGRoup)
            {
                _returnItems["langId"] = "-1";
                props.setAddGroup(true);
            }
            if(fieldItem.fieldName === "discount")
            {
                _returnItems["discount"] = _returnItems["discount"] / 100;
            }
            if(fieldItem.fieldName === "tax")
            {
                _returnItems["tax"] = _returnItems["tax"] / 100;
            }
            if(fieldItem.fieldName === "rate")
            {
                _returnItems["rate"] = _returnItems["rate"] / 100;
            }
            if(fieldItem.multiplier && multiplier)
            {
                _returnItems[fieldItem.fieldName] = _returnItems[fieldItem.fieldName] * fieldItem.multiplier;
            }
        });
        setReturnItems(_returnItems);
    }

    return (
        <>
            <Dialog
                open={!manipulateIt}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                aria-describedby="form-dialog-description"
                maxWidth={"md"}
                fullWidth
                scroll="body"
                classes={{paper: classes.dialog}}
            >
                <div className="gradient">
                    <div className={classes.closeIcon}>
                        {/* <DialogTitle>{props.item.name}</DialogTitle> */}
                        <IconButton edge="end" color="secondary" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <DialogTitle className={classes.dialogTitle} id="form-dialog-title">{props.actionType} {props.name}</DialogTitle>
                    <div className={classes.dialogContent}>
                            <form encType="multipart/form-data" onSubmit={enterManipulateAccept} className={classes.form}>
                                {
                                    fieldList.map((item, index) => (
                                        <Form 
                                            key={"form_field_"+index+refresh} 
                                            fieldList={stateObj} 
                                            item={index} 
                                            setItemsFunction={setItemsFunction} 
                                            setStateObj={setStateObj} 
                                            line={line} 
                                            />
                                    ))
                                }
                            </form>
                    </div>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={checkErrorFunction()}
                            className={classes.accept}
                            onClick={handleManipulateAccept}
                        >
                            {props.actionType}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>

            {manipulateIt && openSnackBar && !props.returnSuccess && (
                <SnackBarAdd 
                    message={`${props.name} ${user.translate("saved")}!`} 
                    openSnackBar={openSnackBar} 
                    setOpenSnackBar={setOpenSnackBar} 
                />
            )}
            {manipulateIt && openSnackBar && props.returnSuccess && data_add && (
                <SnackBarAdd 
                    message={data_add[props.returnSuccess].message} 
                    openSnackBar={openSnackBar} 
                    setOpenSnackBar={setOpenSnackBar} 
                />
            )}

        </>

    );

}