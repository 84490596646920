import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	InputBase,
	Slide,
	Drawer, List, ListItemText, ListItemIcon, ListItem, Divider, Collapse,
	CardMedia,
	Menu,
	MenuItem,
	alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';

import clsx from 'clsx';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import { UserContext } from '../App';
import config from '../../config';
import Profile from '../profile/Profile';
import RequestPassword from '../profile/RequestPassword';
import helper from '../../common/Helper';

function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
}

const drawerWidth = 240;

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	search: {
		width: '40px',
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.text.secondary, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.text.secondary, 0.25),
		},
		marginLeft: 0,
		// [theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		// },
		transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
	},
	searchFocused: {
		width: '200px',
		"@media screen and (max-width: 375px)": {
			width: '180px',
		},
		"@media screen and (max-width: 320px)": {
			width: '160px',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 1),
		paddingTop: 2,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	searchIcon2: {
		padding: theme.spacing(0, 1),
		paddingTop: 2,
		right: 0,
		top: 0,
		height: '100%',
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 2,
		color: 'inherit',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 0, 1, 5),
		// vertical padding + font size from searchIcon
		paddingRight: `calc(1em + ${theme.spacing(2)})`,
		// transition: theme.transitions.create('width'),
		// transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		width: 0,
		// '&:focus': {
		// 	width: '20ch',
		// 	paddingRight: theme.spacing(1),
		// },
		// [theme.breakpoints.down(600)]: {
		// 	'&:focus': {
		// 		width: '12ch',
		// 	},
		// },
	},
	inputFocused: {
		"& input": {
			width: '20ch',
			"@media screen and (max-width: 375px)": {
				width: '16ch',
			},
			"@media screen and (max-width: 320px)": {
				width: '10ch',
			},
		}
	},
	resetSearch: {
		cursor: "pointer",
		width: 24,
		height: 35,
		maxHeight: 35,
		zIndex: 5,
		position: 'absolute',
		top: 0,
		right: theme.spacing(1),
		color: 'inherit'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		zIndex: 2000
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 72,
		[theme.breakpoints.up('sm')]: {
			width: 72,
		},
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		cursor: "pointer"
		// necessary for content to be below app bar
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		padding: theme.spacing(0, 2),
		fontSize: "1em",
		fontWeight: "700",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		// necessary for content to be below app bar
		// ...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawerContent: {
		width: '100%',
		display: 'flex',
		flexGrow: 1,
		'& .MuiDrawer-paperAnchorDockedLeft,': {
			borderColor: theme.palette.primary.light,
		},
		'& .MuiListItemIcon-root': {
			// color: theme.palette.primary.main,
			color: theme.palette.text.primary,
		},
		'& .MuiDrawer-paper': {
			// backgroundColor: 'transparent',
			// backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.medium} , ${theme.palette.primary.dark})`,
			color: 'white',
		},
		'& ul a .MuiTypography-root': {
			color: theme.palette.text.primary,
		},
		'& ul a .Mui-selected': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	content: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(0),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	drawerItem: {
		transitionProperty: 'padding',
		transitionDuration: theme.transitions.duration.enteringScreen,
		transitionEasing: theme.transitions.easing.sharp,
	},
	drawerItemText: {
		color: theme.palette.text.primary
	},
	listItemIcon: {
		color: theme.palette.text.primary
	},
	hr: {
		border: 'none',
		borderTop: '3px double #e3e3e3',
		color: '#e3e3e3',
		overflow: 'visible',
		textAlign: 'center',
		height: 5,
		width: '100%',
		marginTop: theme.spacing(2),
		borderRadius: '100%',
	},
}));

export default function AppNavigation(props) {
	const classes = useStyles();
	const user = useContext(UserContext);
	const size = useWindowSize();

	const restaurant = props.item;
	const canOrder = props.canOrder || false;
	const drawerMenu = props.drawerMenu;

	const [anchorEl, setAnchorEl] = useState(null);

	const isMenuOpen = Boolean(anchorEl);

	const [openProfile, setOpenProfile] = useState(false);
	const [openPassword, setOpenPassword] = useState(false);

	const isMobile = size.width ? size.width < 600 : undefined;

	const [open, setOpen] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);

	const profile = user.profile;

	let drawerHeight = isMobile ? 56 : 64;

	const [picture, setPicture] = useState(null);

	useLayoutEffect(() => {
		if (picture === null && profile && profile.picture) {
			let _picture = profile.picture.indexOf('http') > -1 ? profile.picture : `${config.apiImagesUrl}/${profile.picture}`;
			setPicture(_picture);

			var img = new Image();
			img.src = _picture;
			img.onerror = () => {
				setPicture(false);
			};
		}
	});

	useLayoutEffect(() => {
		if (!profile || !profile.picture) {
			setPicture(null);
		}
	}, []);

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		history.listen((location, action) => {
			// location is an object like window.location
			// console.log(action, location.pathname)

			if (action === "POP") {
				setOpen(false);
				setOpenProfile(false);
				setOpenPassword(false);
				setAnchorEl(null);
			}
		});
	}, []); // Empty array ensures that effect is only run on mount

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const openLanguage = () => {
		history.push(`/language#${location.pathname}`);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};


	const handleLogout = (e) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
			cache: 'no-cache',
			credentials: 'include',
		};
		fetch(`${config.apiUrl}/logout`, requestOptions)
			.then((response) => {
				document.cookie.split(';').forEach((c) => { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString()); });
				props.refetch();
				history.push('/language');
				// window.location.reload();
			});
		handleMenuClose(e);
	};

	const handleProfile = (e) => {
		setOpenProfile(true);
		handleMenuClose(e);
	};

	const handlePassword = (e) => {
		setOpenPassword(true);
		handleMenuClose(e);
	}

	const callWaiter = () => {
		props.setCallType({...props.callType, callIt: true, type: "waiter"});
		handleMenuClose();
	}
	const askBill = () => {
		props.setCallType({...props.callType, callIt: true, type: "receipt"});
		handleMenuClose();
	}

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
			classes={{ list: "gradient-shoopping-cart" }}
		>
			<MenuItem onClick={callWaiter}>{user.translate('call_waiter')}</MenuItem>
			<MenuItem onClick={askBill}>{user.translate('ask_bill')}</MenuItem>
		</Menu>
	);

	const renderProfile = (
		<Profile setOpenProfile={setOpenProfile} refetch={props.refetch} restaurantId={restaurant ? restaurant.id : false} />
	);
	const renderPassword = (
		<RequestPassword setOpenPassword={setOpenPassword} message={user.translate("request_password_qr")} refetch={props.refetch} />
	);

	const closeDrawer = () => (event) => {
		if (!open || (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
			return;
		}

		setOpen(false);
	};

	let { placeId } = useParams();

	const renderMenuItems = (item, itemClasses, selected) => {
		return (
			<ListItem
				dense={open}
				button
				className={itemClasses.join(' ')}
				selected={selected}>
				{!isMenuOpen && (
					<Tooltip title={item.text} placement="bottom" aria-label={item.text}>
						<ListItemIcon classes={{ root: classes.listItemIcon }}>
							{item.key === "profile" ? (picture ?
								<CardMedia
									component="img"
									style={{ width: 24, height: 24, borderRadius: '100%' }}
									image={picture}
									title="Profile Image"
								/> : <AccountCircle />) : item.icon}
						</ListItemIcon>
					</Tooltip>
				)}
				{isMenuOpen && (
					<ListItemIcon classes={{ root: classes.listItemIcon }}>
						{item.key === "profile" ? (picture ?
							<CardMedia
								component="img"
								style={{ width: 24, height: 24, borderRadius: '100%' }}
								image={picture}
								title="Profile Image"
							/> : <AccountCircle />) : item.icon}
					</ListItemIcon>
				)}
				<ListItemText primary={item.text} className={classes.drawerItemText} />
			</ListItem>
		);
	}

	const renderDrawerMenu = (root, nested) => {
		if (!root) return;

		if (!root.key) root.key = Math.random();
		return (
			<List key={root.key + 'list'}>
				{
					root.map((item, index) => {
						if (item.length) {
							if (index > 0) {
								const dividerKey = root.key + '_d_' + index ;
								const fragmentKey = root.key + '_f';
								return <React.Fragment key={fragmentKey}>
									<Divider key={dividerKey} />
									{renderDrawerMenu(item)}
								</React.Fragment>;
							}
							return renderDrawerMenu(item);
						}

						const selected = drawerMenu === item.key;

						if (item.children) {
							const handleClick = (e) => {
								item.open = !item.open;
								setDrawerMenuOpen(!drawerMenuOpen);
							};
							const collapseKey = item.key + '_c';

							return (
								<React.Fragment key={item.key + '_i'}>
									<ListItem dense={open} button onClick={handleClick} className={classes.drawerItem} selected={selected}>
										<ListItemIcon>{item.icon}</ListItemIcon>
										<ListItemText primary={item.text} />
										{item.open ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse key={collapseKey} in={!item.open} timeout="auto" unmountOnExit>
										{renderDrawerMenu(item.children, true)}
									</Collapse>
								</React.Fragment>
							);
						}

						let itemClasses = nested ? [classes.drawerItem, classes.nested] : [classes.drawerItem];

						if (!item.key) {
							return;
						}

						const link = item.exact ? '/' + item.key : `/place/${placeId}/${item.key}`;

						if (item.key === "divider")
							return <hr key={item.key + '_hr_' + index} className={classes.hr} />;
						else if (!item.popup) return (
							<Link to={link} key={item.key + '_l'} style={{ textDecoration: 'none' }}>
								{
									renderMenuItems(item, itemClasses, selected)
								}
							</Link>
						)
						else return (
							<List onClick={eval("handle" + item.key.capitalize())} key={item.key + '_l'} style={{ textDecoration: 'none' }}>
								{
									renderMenuItems(item, itemClasses, selected)
								}
							</List>
						)
						
					})
				}
			</List >
		);
	};//style={{ color: 'rgba(0, 0, 0, 0.87)' }}

	const renderDrawer = (
		<Drawer
			variant={'temporary'}
			anchor="left"
			open={open}
			id="drawer"
			onClose={closeDrawer}
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx('gradient', {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<div className={classes.logo} onClick={(e) => history.push('/home')}>
				<img src={`${process.env.PUBLIC_URL}/android-icon-72x72.png`} alt={user.translate('e_bar')} width="42px" height="42px" />
			</div>
			<div className={classes.toolbar} >
				<span>{props.place}</span>
			</div>
			{renderDrawerMenu(props.drawer)}
		</Drawer>
	);

	const [search, setSearch] = useState("");

	const resetSearch = () => {
		setSearch("");
		document.querySelector("#search-field").blur();
		document.querySelector("#search-input").blur();
		props.onSearch("");
	}

	const onSearchChange = (e) => {
		let _search = e === "" ? "" : e.target.value;
		if (drawerMenu === "menu") setSearch(_search);
		else {
			resetSearch();
		}
	}

	const onOpenSearch = (e) => {
		setOpenSearch(true);
		if (size.width < 600) {
			document.getElementById("avatar").style.visibility = "hidden";
			document.getElementById("avatar").style.opacity = 0;
			document.getElementById("avatar").style.width = 0;
		}
	};

	useEffect(() => {
		if (!open && openSearch && drawerMenu === "menu") {
			const timer = () => setTimeout(() => props.onSearch(search), 150);
			const timerId = timer();
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [search]);

	useEffect(() => {
		if (search && search.length > 0 && drawerMenu !== "menu") {
			onCloseSearch();
		}
	});

	const onCloseSearch = () => {
		setTimeout(() => {
			setOpenSearch(false);
		}, 150)
		if (size.width < 600) {
			document.getElementById("avatar").style.visibility = "visible";
			document.getElementById("avatar").style.opacity = 1;
			document.getElementById("avatar").style.width = "auto";
		}
		resetSearch();
	};

	return (
		<div
			className={classes.root}
			onClick={closeDrawer()}
			onKeyDown={closeDrawer()}
		>
			{/* {config.apiUrl.indexOf('dev') > -1 && (
				<span style={{
					color: '#333', backgroundColor: '#ffc6d6', top: drawerHeight, position: 'relative'
				}}>DEV SERVER</span>
			)}
			{config.apiUrl.indexOf('dev') === -1 && config.isTest === true && (
				<span style={{ top: 10, left: '50%', position: 'absolute' }}>TEST SERVER</span>
			)}
			{config.isTest === false && profile.isAdmin === 1 && (
				<span style={{
					backgroundColor: '#a72bbd', color: '#ffffff', top: drawerHeight, position: 'relative'
				}}>PRODUCTION SERVER</span>
			)} */}
			{/* <CssBaseline /> */}
			<HideOnScroll {...props}>
				<AppBar elevation={props.transparent ? 0 : 8} className={classes.appBar} color="inherit" style={props.transparent ? { backgroundColor: '#ededed' } : {}}>
					<Toolbar
						style={props.transparent ? { color: 'black' } : {}}
					>
						<IconButton
							onClick={toggleDrawer}
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="open drawer"
							style={{ display: props.drawer ? 'block' : 'none' }}
						>
							<MenuIcon />
						</IconButton>
						<Typography className={classes.title} variant="h6" noWrap id="avatar">
							{props.item}
						</Typography>
						<div className={classes.grow} />
						<div
							className={`${classes.search} ${openSearch ? classes.searchFocused : ''}`}
							style={{ display: search !== undefined ? 'block' : 'none' }}
							id="search-field"
						>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<form autoComplete="off" method="post" action="">
								<InputBase
									placeholder={`${user.translate('search')}...`}
									className={openSearch ? classes.inputFocused : ''}
									edge="end"
									id="search-input"
									// type="search"
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
										// focused: classes.inputFocused
									}}
									onFocus={onOpenSearch}
									onBlur={(e) => search.length === 0 ? onCloseSearch(e) : undefined}
									onChange={onSearchChange}
									value={search}
								/>
							</form>
							{openSearch && (
								<>
									<IconButton
										classes={{ root: classes.resetSearch }}
										style={{ display: search != "" ? "flex" : "none" }}
										onClick={(e) => onCloseSearch()}
									>
										<CloseIcon color="inherit" />
									</IconButton>
									{/* <div className={classes.searchIcon2} >
										<SearchIcon />
									</div> */}
								</>
							)}
						</div>
						{/* <IconButton
							edge="end"
							aria-label="language of current user"
							aria-haspopup="true"
							onClick={openLanguage}
							color="inherit"
						>
							<LanguageIcon />
						</IconButton> */}
						{canOrder && (
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="secondary"
							>
								<img width="24" src="data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PHBhdGggZD0ibTAgMzUyLjY2NWg5MS42NjZ2MTI2LjgzNWgtOTEuNjY2eiIvPjxwYXRoIGQ9Im0zMTAuOTE1IDM1Mi42NjVjMS43NzcgNC45NTEgMi43NTIgMTAuMjggMi43NTIgMTUuODM1IDAgMjUuOTE2LTIxLjA4NCA0Ny00NyA0N2gtNzQuNjY3di0zMGg3NC42NjdjOS4zNzQgMCAxNy03LjYyNiAxNy0xNyAwLTcuMjA3LTQuNTE1LTEzLjM2NS0xMC44NTktMTUuODM1aC0xNTEuMTQydjEyNi44MzVoMTY5Ljc0MmwxMzIuNzM2LTYzLjU5OGMxNy4xMjUtOC4yMDUgMjguMTg5LTI1Ljc3NiAyOC4xODktNDQuNzY1IDAtNi41MjctMS4yNzktMTIuNzU4LTMuNTc5LTE4LjQ3MnoiLz48cGF0aCBkPSJtNTEyIDMyMi42NjV2LTMwaC0yOS4zNGMtNi4wOTMtNTAuNDUxLTI4LjcwOC05Ny4xMjYtNjUuMjA0LTEzMy42MjItMzkuNTcxLTM5LjU3LTkxLjExMi02Mi44MTMtMTQ2LjQ1Ni02Ni4zNzl2LTMwLjE2NGgyNy42Njd2LTMwaC04NS4zMzN2MzBoMjcuNjY2djMwLjE2NGMtNTUuMzQ0IDMuNTY2LTEwNi44ODUgMjYuODA4LTE0Ni40NTYgNjYuMzgtMzYuNDk1IDM2LjQ5Ni01OS4xMTEgODMuMTcxLTY1LjIwNCAxMzMuNjIyaC0yOS4zNHYyOC44MzQgMS4xNjV6bS05OC4zNTgtNDcuNzI1LTI4LjgyNCA4LjMxNmMtMTEuMDM0LTM4LjI0MS0zOC4zMDEtNjkuNDAxLTc0LjgxMS04NS40OTJsMTIuMDk5LTI3LjQ1MmM0NC42NjYgMTkuNjg3IDc4LjAzIDU3LjgyMiA5MS41MzYgMTA0LjYyOHoiLz48L2c+PC9zdmc+" />
							</IconButton>
						)}
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			{canOrder && renderMenu}
			{profile && openProfile && renderProfile}
			{profile && openPassword && renderPassword}
			<div className={classes.drawerContent} style={{ marginTop: drawerHeight }}>
				{props.drawer && renderDrawer}
				<div className={classes.content}>
					{props.children}
				</div>
			</div>
		</div>
	);
}
