import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Avatar,
  CardMedia,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  alpha,
} from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useContext, useEffect, useLayoutEffect, useState } from "react";

import LanguageIcon from "@mui/icons-material/Language";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../config";
import { UserContext } from "../App";
import useWindowDimensions from "../Hooks";
import Profile from "../profile/Profile";
import RequestPassword from "../profile/RequestPassword";
import { LoginFacebookMenu, LoginGoogleMenu } from "views/signin";

// function useWindowSize() {
// 	// Initialize state with undefined width/height so server and client renders match
// 	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
// 	const [windowSize, setWindowSize] = useState({
// 		width: undefined,
// 		height: undefined,
// 	});

// 	useEffect(() => {
// 		// Handler to call on window resize
// 		function handleResize() {
// 			// Set window width/height to state
// 			setWindowSize({
// 				width: window.innerWidth,
// 				height: window.innerHeight,
// 			});
// 		}

// 		// Add event listener
// 		window.addEventListener('resize', handleResize);

// 		// Call handler right away so state gets updated with initial window size
// 		handleResize();

// 		// Remove event listener on cleanup
// 		return () => window.removeEventListener('resize', handleResize);
// 	}, []); // Empty array ensures that effect is only run on mount

// 	return windowSize;
// }

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: "transparent",
    opacity: 1,
    visibility: "visible",
    transition: theme.transitions.create("opacity"),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    // [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: "auto",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    // transition: theme.transitions.create('width'),
    transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: 0,
    // '&:focus': {
    // 	width: '20ch',
    // 	paddingRight: theme.spacing(1),
    // },
    // [theme.breakpoints.down(600)]: {
    // 	'&:focus': {
    // 		width: '12ch',
    // 	},
    // },
  },
  inputFocused: {
    "& input": {
      width: "20ch",
      "@media screen and (max-width: 375px)": {
        width: "16ch",
      },
      "@media screen and (max-width: 320px)": {
        width: "10ch",
      },
    },
  },
  endAdornement: {
    cursor: "pointer",
    width: 24,
    height: 35,
    maxHeight: 35,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerContent: {
    // position: 'relative',
    width: "100%",
    display: "flex",
    flexGrow: 1,
    "& .MuiDrawer-paperAnchorDockedLeft,": {
      borderColor: theme.palette.primary.light,
    },
    "& .MuiListItemIcon-root": {
      // color: theme.palette.primary.main,
      color: theme.palette.text.primary,
    },
    "& .MuiDrawer-paper": {
      // backgroundColor: 'transparent',
      // backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.medium} , ${theme.palette.primary.dark})`,
      color: "white",
    },
    "& ul a .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
    "& ul a .Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0),
  },
  hr: {
    border: "none",
    borderTop: "3px double #e3e3e3",
    color: "#e3e3e3",
    overflow: "visible",
    textAlign: "center",
    height: 5,
    width: "100%",
    marginTop: theme.spacing(2),
    borderRadius: "100%",
  },
}));

export default function HomeNavigation(props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const { height, width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);

  const [openProfile, setOpenProfile] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const profile = user.profile;

  const isMenuOpen = Boolean(anchorEl);

  const isMobile = width ? width < 600 : undefined;

  const [open, setOpen] = useState(isMobile === false);
  const [picture, setPicture] = useState(null);

  let drawerHeight = isMobile ? 56 : 64;

  useEffect(() => {
    if (isMobile === false && open === false) {
      setOpen(true);
    } else if (isMobile === true && open === true) {
      setOpen(false);
    }
  }, [isMobile]);

  useLayoutEffect(() => {
    if (picture === null && profile && profile.picture) {
      let _picture =
        profile.picture.indexOf("http") > -1
          ? profile.picture
          : `${config.apiImagesUrl}/${profile.picture}`;
      setPicture(_picture);

      var img = new Image();
      img.src = _picture;
      img.onerror = () => {
        setPicture(false);
      };
    }
  });

  useLayoutEffect(() => {
    if (!profile || !profile.picture) {
      setPicture(null);
    }
  }, []);

  const history = useHistory();
  const location = useLocation();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openLanguage = (event) => {
    history.push(`/language#${location.pathname}`);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = (e) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      cache: "no-cache",
      credentials: "include",
    };
    fetch(`${config.apiUrl}/logout`, requestOptions).then((response) => {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString());
      });
      props.refetch();
      history.push("/language");
      // window.location.reload();
    });
    handleMenuClose(e);
  };

  const handleLogIn = (e) => {
    history.push("/login");
  };

  const onOpenSearch = (e) => {
    if (isMobile) {
      document.getElementById("avatar").style.visibility = "hidden";
      document.getElementById("avatar").style.opacity = 0;
      document.getElementById("avatar").style.width = 0;
    }
  };

  const onCloseSearch = (e) => {
    if (isMobile) {
      document.getElementById("avatar").style.visibility = "visible";
      document.getElementById("avatar").style.opacity = 1;
      document.getElementById("avatar").style.width = "auto";
    }
  };
  const resetSearch = (e) => {
    e.stopPropagation();
    props.onSearch("");
    document.getElementsByClassName("search")[0].blur();
  };

  const handleProfile = (e) => {
    setOpenProfile(true);
    handleMenuClose(e);
  };

  const handleChangePassword = (e) => {
    setOpenPassword(true);
    handleMenuClose(e);
  };

  const callWaiter = () => {};
  const askBill = () => {};

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{ list: "gradient-shoopping-cart" }}
    >
      {profile && (
        <MenuItem onClick={handleProfile}>
          {user.translate("my_profile")}
        </MenuItem>
      )}
      {/* {(!profile || profile.fbId === null || profile.fbId === "") && (
        <LoginFacebookMenu onLogin={props.refetch} />
      )}
      {(!profile || profile.googleId === null || profile.googleId === "") && (
        <LoginGoogleMenu onLogin={props.refetch} />
      )} */}
      {profile && profile.email !== "" && profile.isEmailVerified === 1 && (
        <MenuItem onClick={handleChangePassword}>
          {user.translate("change_password")}
        </MenuItem>
      )}

      {/* <hr className={classes.hr}/>

			<MenuItem onClick={callWaiter}>{user.translate('call_waiter')}</MenuItem>
			<MenuItem onClick={askBill}>{user.translate('ask_bill')}</MenuItem>

			<hr className={classes.hr}/> */}

      {profile && (
        <MenuItem onClick={handleLogOut}>{user.translate("logout")}</MenuItem>
      )}
      {!profile && (
        <MenuItem onClick={handleLogIn}>{user.translate("signin")}</MenuItem>
      )}
    </Menu>
  );

  const renderProfile = (
    <Profile setOpenProfile={setOpenProfile} refetch={props.refetch} />
  );
  const renderPassword = (
    <RequestPassword
      setOpenPassword={setOpenPassword}
      message={user.translate("request_password_qr")}
      refetch={props.refetch}
    />
  );

  const closeDrawer = () => (event) => {
    if (
      !isMobile ||
      !open ||
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift"))
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <div
      className={classes.root}
      onClick={closeDrawer()}
      onKeyDown={closeDrawer()}
    >
      {/* {config.apiUrl.indexOf('dev') > -1 && (
				<span style={{
					color: '#333', backgroundColor: '#ffc6d6', top: drawerHeight, position: 'relative'
				}}>DEV SERVER</span>
			)}
			{config.apiUrl.indexOf('dev') === -1 && config.isTest === true && (
				<span style={{ top: 10, left: '50%', position: 'absolute' }}>TEST SERVER</span>
			)}
			{config.isTest === false && profile.isAdmin === 1 && (
				<span style={{
					backgroundColor: '#a72bbd', color: '#ffffff', top: drawerHeight, position: 'relative'
				}}>PRODUCTION SERVER</span>
			)} */}
      <HideOnScroll {...props}>
        <AppBar
          elevation={props.transparent ? 0 : 8}
          className={classes.appBar}
          color="inherit"
          style={props.transparent ? { backgroundColor: "#ededed" } : {}}
        >
          <Toolbar style={props.transparent ? { color: "black" } : {}}>
            <Avatar className={classes.avatar} id="avatar">
              <img
                src={`${process.env.PUBLIC_URL}/android-icon-72x72.png`}
                alt={user.translate("e_bar")}
                width={isMobile ? "35px" : "42px"}
                height={isMobile ? "35px" : "42px"}
              />
            </Avatar>
            <div className={classes.grow} />
            <div
              className={classes.search}
              style={{ display: props.search !== undefined ? "block" : "none" }}
            >
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={`${user.translate("search")}...`}
                className="search"
                // type="search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                  focused: classes.inputFocused,
                }}
                onFocus={onOpenSearch}
                onChange={(e) => props.onSearch(e)}
                onBlur={onCloseSearch}
                value={props.search}
                endAdornment={
                  <InputAdornment
                    classes={{ root: classes.endAdornement }}
                    style={{ display: props.search !== "" ? "flex" : "none" }}
                    onClick={resetSearch}
                    position="end"
                  >
                    <CloseIcon color="inherit" />
                  </InputAdornment>
                }
              />
            </div>
            <IconButton
              edge="end"
              aria-label="language of current user"
              aria-haspopup="true"
              onClick={openLanguage}
              color="inherit"
            >
              <LanguageIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {picture && (
                <CardMedia
                  component="img"
                  style={{ width: 24, height: 24, borderRadius: "100%" }}
                  image={picture}
                  title="Profile Image"
                />
              )}
              {!picture && <AccountCircle />}
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMenu}
      {openProfile && renderProfile}
      {openPassword && renderPassword}
      <div
        className={classes.drawerContent}
        style={{ marginTop: drawerHeight }}
      >
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
}
